import './App.css';
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthContext } from './components/auth/Context';
import { Signup, LoggedIn, Login, Logout } from './components/auth/AuthService';

import Facturas from './components/Facturas';
import Inventario from './components/Inventario';
import Tickets from './components/Tickets';
import Print from './components/Print';
import Altas from './components/Altas';
import Navbar from './components/Navbar';
import Ventas from './components/Ventas';
import Cocina from './components/Cocina';
import Gastos from './components/Gastos';
import Footer from './components/Footer';
import Auth from './components/auth/Signup';
import Perfil from './components/Perfil';
import Usuarios from './components/Usuarios';

function App() {
  const [loggedIn, setLoggedIn] = useState({loggedInUser: null});

  useEffect(() => {
    if(loggedIn.loggedInUser === null){
      LoggedIn()
      .then(response => {        
        setLoggedIn({loggedInUser: response})
      })
      .catch(err => {
        console.log('No se iniciò sesión', err)
        setLoggedIn({
          loggedInUser: false
        })
      })
    }
  }, [loggedIn])
  
  const getTheUser = (userObj) => {    
    setLoggedIn({
      loggedInUser: userObj
    })
  }
  
  return (
    <div className="App">
      <AuthContext.Provider value={{isLoggedIn: loggedIn, signup: Signup, user: getTheUser, login: Login, logout: Logout}}>
        <Navbar/>
        <Routes>
          <Route path='/facturas' element={<Facturas/>}/>
          <Route path='/carta' element={<Inventario/>}/>
          <Route path='/cocina' element={<Cocina/>}/>
          <Route path='/tickets' element={<Tickets/>}/>
          <Route path='/imprimir/:id' element={<Print/>}/>
          <Route path='/altas' element={<Altas/>}/>
          <Route path='/ventas' element={<Ventas/>}/>
          <Route path='/gastos' element={<Gastos/>}/>
          <Route path='/signup' element={<Auth/>}/>
          <Route path='/perfil/:id' element={<Perfil/>}/>
          <Route path='/usuarios' element={<Usuarios/>}/>
        </Routes>
        <Footer/>
      </AuthContext.Provider>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function Print () {
  const [facts, setFacts] = useState({
    _id: 0,
    mesa: 0,
    personas: 0,
    consumo: [],
    pago: "",
    total: 0,
    propina: 0,
    descuento: 0,
    granTotal: 0,
    activo: false,
    factura: ""
  });
  const [fecha, setFecha] = useState('');  
  const formatter = new Intl.DateTimeFormat('es-MX', {
    hour: 'numeric',
    hour12: false,
    minute: 'numeric',
    weekday: 'long',
    timeZone: 'America/Mexico_City',
    day: 'numeric'
  })
  const { id }  = useParams();
  const MXN     = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});
  let subtotal = facts.consumo.length > 0 ? facts.consumo.reduce((t, n) => t + n.precio, 0) : 0
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/ticketPrint/${id}`, {withCredentials: true})
    .then(response => {
      let nf = new Date(response.data[0].updatedAt)
      setFacts(response.data[0]);
      setFecha(nf)     
    })
    .catch(err => {console.log(err)})
  }, [id])

  var print = () => {
    var mesa      = facts.mesa;
    var consumo   = facts.consumo;
    var descuento = facts.descuento;
    var propina   = facts.propina;
    var total     = subtotal - descuento + propina;
    var ticketID  = facts._id;
    var pago      = facts.pago;
    var date      = fecha;

    axios.post(`${process.env.REACT_APP_API_URL}/imprimirTicket/`, {mesa, consumo, descuento, propina, total, ticketID, pago, date}, {withCredentials: true})
    .then(response => {
      console.log(response)
    })
    .catch(err => {console.log(err)})
  }

  return(   
    <div onClick={() => print()} style={{ borderRadius: '10px', margin: '2rem', textAlign: 'left', width:'400px'}}>
      <img src={`${process.env.PUBLIC_URL}/Logo-Kas.png`} alt="Kas" style={{width:'15rem', margin:'auto', textAlign: 'center'}}/>
      <p><b>Ticket</b></p>
      <p id="tic">{facts._id}</p>
      <p><b>Mesa</b></p>
      <p>{facts.mesa}</p>
      <p>Hora: {formatter.format(fecha)}</p>
      <p><b>Personas</b></p>
      <p>{facts.personas}</p>
      <p><b>Subtotal</b></p>
      <p>{MXN.format(subtotal)}</p>
      <p><b>Descuento</b></p>
      <p>{MXN.format(facts.descuento)}</p>
      <p><b>Propina</b></p>
      <p>{MXN.format(facts.propina)}</p>
      <p><b>Total</b></p>
      <p>{MXN.format(subtotal - facts.descuento + facts.propina)}</p>
      <div style={{backgroundColor: 'rgba(230,223,210,.5)'}}>        
        <table style={{width: '100%', marginBottom: '4rem'}}>
          <thead>
            <tr style={{backgroundColor: 'rgba(86, 144, 149, .7'}}>                        
              <th>Cantidad</th>
              <th>Nombre</th>
              <th>Precio</th>
            </tr>                      
          </thead>
          <tbody>
            {facts.consumo ? facts.consumo.map((cons, idx) => {
              return(
                <tr key={idx}>                            
                  <td>{cons.cantidad}</td>
                  <td>{cons.nombre}</td>
                  <td>{MXN.format(cons.precio)}</td>
                </tr>
              )
            }) : null}                      
          </tbody>
        </table>                       
      </div>                                    
    </div>
  )
}

export default Print;
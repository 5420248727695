import React, { useContext, useState } from 'react';

import { AuthContext } from './Context';

function Signup () {  
  const authContext       = useContext(AuthContext);
  const [error, setError] = useState(''); 
  const [tab, setTab]     = useState('signup') 

  var signup = (e) => {
    e.preventDefault();
    var user = document.getElementById("input-username").value;
    var username     = document.getElementById("input-email").value;
    var password = document.getElementById("input-password").value;

    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE ALTA? Usuario: ${user}; Correo: ${username}`
    )){
      authContext.signup(user, username, password)      
      .then(response => {
        authContext.login(username, password)
        .then(response => {
          document.getElementById("input-username").value = "";
          document.getElementById("input-email").value = "";
          document.getElementById("input-password").value = ""; 
          authContext.user(response)
          window.location.replace(`/perfil/${response._id}`)  
        })             
      })
      .catch(err => {
        setError(err.response.data)
        console.log(err)
      })
      return
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  var login = (e) => {
    e.preventDefault();    
    var username = document.getElementById("input-email-login").value;
    var password = document.getElementById("input-password-login").value;

    authContext.login(username, password)    
    .then(response => {      
      document.getElementById("input-email-login").value = "";
      document.getElementById("input-password-login").value = "";
      authContext.user(response)      
      window.location.replace(`/perfil/${response._id}`)      
    })
    .catch(err => {
      setError(err.response.data)
      console.log(err)
    })
    return
  
  }

  return(
   <div>
    {tab && tab === 'signup' ? 
    <div style={{width: '60%', margin: 'auto', fontFamily: 'proxima-nova', padding: '5%'}}>
      <h2 style={{backgroundImage: 'linear-gradient(to right, #e6dfd2, rgba(255,255,255,0), rgba(255,255,255,0))', fontFamily: 'fields', textAlign: 'left'}}>signup</h2> 
      <form action="/signup">
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label htmlFor="input-username" style={{width: '100%', fontWeight: 'bold'}}>Usuario</label>
          <input type="text" id='input-username' name='username' placeholder='Usuario' style={{width: '100%', height: '30px', padding: '5px', border: 'none', borderBottom: 'solid black'}}/>
        </div>
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label htmlFor="input-email" style={{width: '100%', fontWeight: 'bold'}}>Email</label>
          <input type="email" id='input-email' name='email' placeholder='Correo electrónico' style={{width: '100%', height: '30px', padding: '5px', border: 'none', borderBottom: 'solid black'}}/>
        </div>
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label htmlFor="input-password" style={{width: '100%', fontWeight: 'bold'}}>Password</label>
          <input type="password" name='password' id='input-password' placeholder='**********' style={{width: '100%', height: '30px', padding: '5px', border: 'none', borderBottom: 'solid black'}}/>
        </div>
        <div style={{padding: '10px', textAlign: 'center', margin: '20px'}}>
          <button type='submit' onClick={(e) => signup(e)} style={{width: '90%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}}>Crear Cuenta</button>
        </div>    
      </form>
      <div style={{marginBottom: '40%'}}>
        <div>
          <p style={{textAlign: 'left'}}>¿Ya tienes cuenta? <b onClick={() => setTab('login')} style={{color: 'blue', cursor: 'pointer'}}>Ingresa desde aquí</b></p>
        </div>
        {error !== "" ? 
          <p style={{backgroundColor: 'rgba(218, 73, 73, 1)', color: 'white', padding: '10px', borderRadius: '10px'}}>{error}</p> :
          <p></p>
        }        
      </div>
    </div>  
    : 
    <div style={{width: '60%', margin: 'auto', fontFamily: 'proxima-nova', padding: '5%'}}>
      <h2 style={{backgroundImage: 'linear-gradient(to right, #e6dfd2, rgba(255,255,255,0), rgba(255,255,255,0))', fontFamily: 'fields', textAlign: 'left'}}>login</h2> 
      <form action={`${process.env.REACT_APP_API_URL}/login`} method='POST'>      
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label htmlFor="input-email-login" style={{width: '100%', fontWeight: 'bold'}}>Email</label>
          <input type="email" id='input-email-login' name='email' placeholder='Correo electrónico' style={{width: '100%', height: '30px', padding: '5px', border: 'none', borderBottom: 'solid black'}}/>
        </div>
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label htmlFor="input-password-login" style={{width: '100%', fontWeight: 'bold'}}>Password</label>
          <input type="password" name='password' id='input-password-login' placeholder='**********' style={{width: '100%', height: '30px', padding: '5px', border: 'none', borderBottom: 'solid black'}}/>
        </div>
        <div style={{padding: '10px', textAlign: 'center', margin: '20px'}}>
          <button type='submit' onClick={(e) => login(e)} style={{width: '90%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}}>Ingresar</button>
        </div>    
      </form>
      <div style={{marginBottom: '40%'}}>
        <div>
          <p style={{textAlign: 'left'}}>¿No tienes cuenta? <b onClick={() => setTab('signup')} style={{color: 'blue', cursor: 'pointer'}}>Registrate aquí</b></p>
        </div>
        {error !== "" ? 
          <p style={{backgroundColor: 'rgba(218, 73, 73, 1)', color: 'white', padding: '10px', borderRadius: '10px'}}>{error}</p> :
          <p></p>
        }        
      </div>
    </div>}       
   </div>
  )

}

export default Signup;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Altas () {
  const [merch, setMerch]       = useState([]);
  const [beer, setBeer]         = useState([]);
  const [gen, setGen]           = useState({
    folio: "", 
    proveedor: "", 
    entrega: new Date(), 
    credito: "", 
    valor: "", 
    iva: "", 
    ieps: "", 
    numCervezas: "", 
    vtaCervezas: "", 
    pago: "", 
    estatusPago: ""
  });
  const [cocina, setCocina]     = useState('block'); 
  const [factura, setFact]      = useState('none'); 
  const [cuenta, setCuenta]     = useState('none');
  const [poliza, setPoliza]     = useState('none');
  const [polizas, setPolizas]   = useState([]);
  const [filPol, setFilPol]     = useState([]);
  const [filIngr, setFilingr]   = useState([]);
  const [ingr, setIngr]         = useState([]);  
  const [ctas, setCtas]         = useState([]);
  const [fsCtas, setFSctas]     = useState([]);
  const [factos, setFactos]     = useState([]);

  const MXN = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});
  const Num = new Intl.NumberFormat();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/all`, {withCredentials: true})
    .then(response => {
      setFactos(response.data);      
    })
    .catch(err => {console.log(err)})

    axios.get(`${process.env.REACT_APP_API_URL}/ingredientes`, {withCredentials: true})
    .then(response => {
      setIngr(response.data);
      setFilingr(response.data)     
    })
    .catch(err => {console.log(err)})

    axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
    .then(response => {
      var temp = [];
      setCtas(response.data);
      for(var d = 0; d < response.data.length; d++){                
        for(var c = 0; c < response.data[d].corto.length; c++){
          response.data[d].corto[c].plzo = "corto"
          response.data[d].corto[c].pos = c
          response.data[d].corto[c].clase = response.data[d].clase
          temp.push(response.data[d].corto[c])
        }
        for(var l = 0; l < response.data[d].largo.length; l++){
          response.data[d].largo[l].plzo = "largo"
          response.data[d].largo[l].pos = l
          response.data[d].largo[l].clase = response.data[d].clase
          temp.push(response.data[d].largo[l])
        }        
      }                 
      setFSctas(temp);
    })
    .catch(err => console.log(err));

    axios.get(`${process.env.REACT_APP_API_URL}/poliza`, {withCredentials: true})
    .then(response => {
      setPolizas(response.data);
      setFilPol(response.data);
    })
    .catch(err => console.log(err));

    var url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vSqOw9LD5YM9BChb4TFkMU97VM6289L2UCgU9P8-zTcRuhScXr-fQGmi3Z6OXmpdAOcfBTMF_HQjCZ8/pub?gid=0&single=true&range=A1:AA100&output=csv";
    
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
      if(xmlhttp.readyState === 4 && xmlhttp.status === 200){        
        var re       = xmlhttp.response;
        var rows     = re.split('\n');
        var arr      = [];
        var cerveza  = [];
        var merch    = [];

        for (const row in rows){
          const values = rows[row].split(",")          
          arr.push(values)
        }
        
        var doclen = arr[9].length;
        var merlen = arr[32].length;        
        
        var dg     = {
          folio: arr[1][1], 
          proveedor: arr[2][1], 
          entrega: arr[3][1] ? new Date(arr[3][1].slice(-4), arr[3][1].slice(0,2) - 1, arr[3][1].slice(3,5)) : "", 
          credito: Number(arr[4][1]), 
          valor: Number(arr[5][1]), 
          iva: Number(arr[6][1]), 
          ieps: Number(arr[7][1]), 
          numCervezas: doclen - 1, 
          vtaCervezas: 0, 
          pago: arr[8][1], 
          estatusPago: arr[9][1]
        }
        
        setGen(dg);
      
        for (var d = 1; d < doclen; d++){
          var temp
          if(doclen.length === 0){
            temp = {}
          } else {         
            temp = {
              imagen: d === doclen -1 ? arr[11][d].slice(0,-1) : arr[11][d], 
              nombre: d === doclen -1 ? arr[12][d].slice(0,-1) : arr[12][d],
              precio: d === doclen -1 ? Number(arr[13][d].slice(0,-1)) : Number(arr[13][d]), 
              costo: d === doclen -1 ? Number(arr[14][d].slice(0,-1)) : Number(arr[14][d]),
              cantidad: d === doclen -1 ? Number(arr[17][d].slice(0,-1)) : Number(arr[17][d]), 
              vendidas: 0,
              estilo: d === doclen -1 ? arr[18][d].slice(0,-1) : arr[18][d], 
              tag: d === doclen -1 ? arr[19][d].slice(0,-1) : arr[19][d], 
              cervecera: d === doclen -1 ? arr[20][d].slice(0,-1) : arr[20][d], 
              descripcion: d === doclen -1 ? arr[21][d].slice(0,-1).replace('\\', '') : arr[21][d].replace('\\', ''), 
              codigo: d === doclen -1 ? arr[22][d].slice(0,-1) : arr[22][d], 
              pais: d === doclen -1 ? arr[23][d].slice(0,-1) : arr[23][d],
              region: d === doclen -1 ? arr[24][d].slice(0,-1) : arr[24][d], 
              abv: d === doclen -1 ? Number(arr[25][d].slice(0,-1)) : Number(arr[25][d]), 
              ibu: d === doclen -1 ? Number(arr[26][d].slice(0,-1)) : Number(arr[26][d]),
              iva: d === doclen -1 ? Number(arr[15][d].slice(0,-1)) : Number(arr[15][d]),
              ieps: d === doclen -1 ? Number(arr[16][d].slice(0,-1)) : Number(arr[16][d]),
              maltas: d === doclen -1 ? arr[28][d].slice(0,-1).split("-") : arr[28][d].split("-"), 
              lupulos: d === doclen -1 ? arr[29][d].slice(0,-1).split("-") : arr[29][d].split("-"),
              tipo: d === doclen -1 ? arr[27][d].slice(0,-1) : arr[27][d],
              merma: 0,
              adjuntos: d === doclen -1 ? arr[30][d].slice(0,-1).split("-") : arr[30][d].split("-"),
              activo: false
            }
          }        
          cerveza.push(temp);
        }  

        for (var m = 1; m < merlen; m++){
          var pr = m === merlen - 1 ? arr[32][m].slice(0,-1) : arr[32][m]
          if(pr !== ""){
            var mertemp = {               
              producto: m === merlen - 1 ? arr[32][m].slice(0,-1) : arr[32][m], 
              marca: m === merlen - 1 ? arr[33][m].slice(0,-1) : arr[33][m], 
              costo: m === merlen - 1 ? Number(arr[34][m].slice(0,-1)) : Number(arr[34][m]),
              capacidad: m === merlen - 1 ? arr[35][m].slice(0,-1) : arr[35][m], 
              cantidad: m === merlen - 1 ? Number(arr[36][m].slice(0,-1)) : Number(arr[36][m]),
              merma: 0          
            }        
            merch.push(mertemp);
          }          
        }      
        setBeer(cerveza);
        setMerch(merch);        
      }
    };
    xmlhttp.open("GET", url, true);
    xmlhttp.send(null);
  }, [])

  var addBeer = (e) => {    
    var beers = [];
    
    for(var f = 0; f < factos.length; f++){
      if(!factos[f].enBD){        
        for(var b = 0; b < factos[f].cerveza.length; b++){ 
          var be = factos[f].cerveza[b];
          be.proveedor = factos[f].proveedor;
          be.factura = factos[f]._id;
          
          axios.post(`${process.env.REACT_APP_API_URL}/getBeer`,{beer: be}, {withCredentials: true})
          .then(response => {            
            console.log(response)
          })
          beers = [...beers, ...factos[f].cerveza]
        }        
        axios.put(`${process.env.REACT_APP_API_URL}/beerAdded`,{id: factos[f]._id}, {withCredentials: true})
        .then(response => {
          console.log(response)
        })
        .catch(err => console.log(err))      
      }
      else {
        console.log(`La factura con id: ${factos[f]._id} ya se encuentra en la BD`)
      }
    } 

    alert('Se dio de alta la cerveza de las facturas')     
  }

  var addFact = (e) => {        
    var cuenta    = "corto-Activo-Almacén"
    var ctaCred   = "corto-Pasivo-Proveedores"  

    axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})    
    .then(res => {        
      var cuentas     = res.data;           
      var credito     = gen.credito === 0 ? false : true;
      console.log(gen)
      var act         = cuentas.filter(f => f.clase === "Activo"); 
      var posBan      = act[0].corto.map(m => m.cuenta).indexOf("Bancos");   
      var posSan      = act[0].corto[posBan].subcuentas.map(m => m.subcuenta).indexOf("Santander");
      var posAlm      = act[0].corto.map(m => m.cuenta).indexOf("Almacén");
      var posInv      = act[0].corto[posAlm].subcuentas.map(m => m.subcuenta).indexOf(gen.proveedor);
      var invNum      = act[0].corto[posAlm].subcuentas.length;    
      var pas         = cuentas.filter(f => f.clase === "Pasivo");    
      var posProv     = pas[0].corto.map(m => m.cuenta).indexOf("Proveedores");
      var posDeu      = pas[0].corto[posProv].subcuentas.map(m => m.subcuenta).indexOf(gen.proveedor);
      var deuNum      = pas[0].corto[posProv].subcuentas.length;
      var posIVAc     = act[0].corto.map(m => m.cuenta).indexOf("IVA acreedor");
      var posIVAsc    = act[0].corto[posIVAc].subcuentas.map(m => m.subcuenta).indexOf("IVA acreditable");
      var posIVAxsc   = act[0].corto[posIVAc].subcuentas.map(m => m.subcuenta).indexOf("IVA por acreditar");    
      var tipo        = "Egreso";            
      var cuentaCargo = "corto-Activo-Bancos-Santander";                                    
      var cuentaAbono = "corto-Activo-Almacén-" + gen.proveedor;            
      var concepto    = `Compra de cerveza del proveedor ${gen.proveedor}`;
      var monto       = Number(gen.valor) - Number(gen.iva) - Number(gen.ieps);
      var iva         = Number(gen.iva);
      var referencia  = gen.folio;
      var rfc         = "";
      var fecha       = new Date();
      var ctaCrgoCred = "corto-Pasivo-Proveedores-" + gen.proveedor;                                        
      var ivaAcreCta  = "corto-Activo-IVA acreedor-IVA acreditable";                                    
      var ivaXacreCta = "corto-Activo-IVA acreedor-IVA por acreditar";
      
      if(posInv === -1){
        axios.put(`${process.env.REACT_APP_API_URL}/subcuenta`, {id: cuenta, subcuenta: gen.proveedor}, {withCredentials: true})
        .then(response => {                               
          console.log(response)
        })
        .catch(err => console.log(err))
      } 

      if(posDeu === -1 && gen.credito !== 0){
        axios.put(`${process.env.REACT_APP_API_URL}/subcuenta`, {id: ctaCred, subcuenta: gen.proveedor}, {withCredentials: true})
        .then(response => {                               
            console.log(response)
        })
        .catch(err => console.log(err))
      }  
        
      axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: credito ? ctaCrgoCred : cuentaCargo, cuentaAbono, concepto, monto, referencia, rfc, fecha}, {withCredentials: true})
      .then(r => {  
        var mes = credito ? "Poliza credito---->" : "Poliza contado---->"
        console.log(mes, r)
      })              
      .catch(err => console.log(err));

      axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: credito ? ctaCrgoCred : cuentaCargo, cuentaAbono: credito ? ivaXacreCta : ivaAcreCta, concepto, monto: iva, referencia, rfc, fecha}, {withCredentials: true})
      .then(r => {  
        var mes = credito ? "Poliza iva credito---->" : "Poliza iva contado---->"
        console.log(mes, r)
      })              
      .catch(err => console.log(err));

      var cargo = credito ? `corto-Pasivo-Proveedores-Subcuenta-${posDeu === -1 ? deuNum : posDeu}-${posProv}` : `corto-Activo-Bancos-Santander-${posSan}-${posBan}`
      var abono = `corto-Activo-Almacén-Subcuenta-${posInv === -1 ? invNum : posInv}-${posAlm}`        
      var abIVA = `corto-Activo-IVA acreedor-Subcuenta-${credito ? posIVAxsc : posIVAsc}-${posIVAc}`                             

      axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo, abono, monto}, {withCredentials: true})
      .then(rpn => {
        var mes = credito ? "Movimientos crédito--->" : "Movimientos de contado--->"
        console.log(mes,rpn)
      })
      .catch(err => console.log(err));    

      axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo, abono: abIVA, monto: iva}, {withCredentials: true})
      .then(rpn => {
        var mes = credito ? "Movimientos IVA crédito--->" : "Movimientos IVA de contado--->"
        console.log(mes,rpn)
      })
      .catch(err => console.log(err));        
            
      axios.post(`${process.env.REACT_APP_API_URL}/inventario`, {
        folio: gen.folio, 
        proveedor: gen.proveedor, 
        entrega: gen.entrega, 
        credito: Number(gen.credito), 
        valor: Number(gen.valor), 
        iva: Number(gen.iva), 
        ieps: Number(gen.ieps), 
        numCervezas: Number(beer.length), 
        vtaCervezas: 0,       
        pago: gen.pago, 
        estatusPago: gen.estatusPago, 
        merch: merch, 
        cerveza: beer
      }, {withCredentials: true})
      .then(response => console.log(response))
      .catch(err => console.log(err))
    })

    alert('Se dio de alta la factura')
  }

  var altaCant = () => {
    var item = document.getElementById("selProdCh");
    var id = item.value;
    var nom = item.options[item.selectedIndex].text;
    var cantidad = document.getElementById("prodMas").value;
    var cred     = document.getElementById("cred").value;
    
    if(id === "" || cantidad === ""){
      alert("Completa los campos de 'Agregar existencias'");
      return
    }

    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE ALTA? Nombre: ${nom}; Gramaje: ${Num.format(cantidad)}`
    )){

      axios.put(`${process.env.REACT_APP_API_URL}/ingrediente`, {id, cantidad}, {withCredentials: true})
      .then(response => {
        var marca = response.data.marca;
        var costo = response.data.costo;
        var gramaje = response.data.gramaje;

        var tipo        = "Egreso";            
        var cuentaCargo = "corto-Activo-Bancos-Santander";                                    
        var cuentaAbono = "corto-Activo-Almacén-" + marca;            
        var concepto    = "Compra de insumos de cocina.";
        var monto       = ((cantidad * costo) / gramaje) * 0.84;
        var iva         = ((cantidad * costo) / gramaje) * 0.16;
        var referencia  = "";
        var rfc         = "";
        var fecha       = new Date(); 
        var ctaCrgoCred = "corto-Pasivo-Proveedores-" + marca;                                        
        var ivaAcreCta  = "corto-Activo-IVA acreedor-IVA acreditable";                                    
        var ivaXacreCta = "corto-Activo-IVA acreedor-IVA por acreditar";       


        if(cred === "Contado"){            
          axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo, cuentaAbono, concepto, monto, referencia, rfc, fecha}, {withCredentials: true})
          .then(r => {  
            console.log("Poliza contado---->", r)
          })              
          .catch(err => console.log(err));

          axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo, cuentaAbono: ivaAcreCta, concepto, monto: iva, referencia, rfc, fecha}, {withCredentials: true})
          .then(r => {  
            console.log("Poliza iva contado---->", r)
          })              
          .catch(err => console.log(err));

          axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
          .then(responseG => {
            var temp = [];
            var ct = responseG.data;                
            setCtas(ct);
            for(var d = 0; d < responseG.data.length; d++){                
              for(var c = 0; c < responseG.data[d].corto.length; c++){
                responseG.data[d].corto[c].plzo = "corto"
                responseG.data[d].corto[c].clase = responseG.data[d].clase
                temp.push(responseG.data[d].corto[c])                    
              }
              for(var l = 0; l < responseG.data[d].largo.length; l++){
                responseG.data[d].largo[l].plzo = "largo"
                responseG.data[d].largo[l].clase = responseG.data[d].clase
                temp.push(responseG.data[d].largo[l])
              }        
            }                 
            setFSctas(temp);

            var sc    = "";
            var pc    = "";
            var sca   = "";
            var pca   = "";
            var iv    = "";
            var iac   = "";         

          
            for(var cc = 0; cc < ct[0].corto.length; cc++){                                        
              if(ct[0].clase === "Activo" && ct[0].corto[cc].cuenta === "Bancos"){
                pc = cc
              }                    
              if(ct[0].clase === "Activo" && ct[0].corto[cc].cuenta === "Almacén"){
                pca = cc;
              }
              if(ct[0].clase === "Activo" && ct[0].corto[cc].cuenta === "IVA acreedor"){
                iv = cc;
              }
              for(var subC = 0; subC < ct[0].corto[cc].subcuentas.length; subC++){
                if(ct[0].corto[cc].subcuentas[subC].subcuenta === "Santander"){
                  sc = subC;
                }
                if(ct[0].corto[cc].subcuentas[subC].subcuenta === marca){
                  sca = subC;
                }
                if(ct[0].corto[cc].subcuentas[subC].subcuenta === "IVA acreditable"){
                  iac = subC;
                }
              }
            }                        
            
            var cargo = `corto-Activo-Bancos-Santander-${sc}-${pc}`
            var abono = `corto-Activo-Almacén-Subcuenta-${sca}-${pca}`        
            var abIVA = `corto-Activo-IVA acreedor-Subcuenta-${iac}-${iv}`
            
            axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo, abono, monto}, {withCredentials: true})
            .then(rpn => {
              console.log("Movimientos de contado--->",rpn)
            })
            .catch(err => console.log(err));

            axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo, abono: abIVA, monto: iva}, {withCredentials: true})
            .then(rpn => {
              console.log("Movimientos IVA de contado--->",rpn)
            })
            .catch(err => console.log(err));
          })             
          .catch(err => console.log(err));     
        }

        if(cred === "Credito"){            
          axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: ctaCrgoCred, cuentaAbono, concepto, monto, referencia, rfc, fecha}, {withCredentials: true})
          .then(r => {  
            console.log("Poliza credito---->", r)
          })              
          .catch(err => console.log(err));

          axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: ctaCrgoCred, cuentaAbono: ivaXacreCta, concepto, monto: iva, referencia, rfc, fecha}, {withCredentials: true})
          .then(r => {  
            console.log("Poliza iva credito---->", r)
          })              
          .catch(err => console.log(err));

          axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
          .then(responseG => {
            var temp = [];
            var ct = responseG.data;                
            setCtas(ct);
            for(var d = 0; d < responseG.data.length; d++){                
              for(var c = 0; c < responseG.data[d].corto.length; c++){
                responseG.data[d].corto[c].plzo = "corto"
                responseG.data[d].corto[c].clase = responseG.data[d].clase
                temp.push(responseG.data[d].corto[c])                    
              }
              for(var l = 0; l < responseG.data[d].largo.length; l++){
                responseG.data[d].largo[l].plzo = "largo"
                responseG.data[d].largo[l].clase = responseG.data[d].clase
                temp.push(responseG.data[d].largo[l])
              }        
            }                 
            setFSctas(temp);

            var csc    = "";
            var cpc    = "";
            var sca   = "";
            var cpca   = "";
            var civ    = "";                               

            for(var cla = 0; cla < ct.length; cla++){        
              for(var cc = 0; cc < ct[cla].corto.length; cc++){                                        
                if(ct[cla].clase === "Pasivo" && ct[cla].corto[cc].cuenta === "Proveedores"){
                  cpc = cc;
                  var prov = ct[cla].corto[cc].subcuentas;
                  for(var p = 0; p < prov.length; p++){                
                    if(prov[p].subcuenta === marca){
                      csc = p;
                    }
                  }                            
                }                    
                if(ct[cla].clase === "Activo" && ct[cla].corto[cc].cuenta === "Almacén"){
                  cpca = cc;
                  var alm = ct[cla].corto[cc].subcuentas;
                  for(var a = 0; a < alm.length; a++){
                    if(alm[a].subcuenta === marca){
                      sca = a;
                    }
                  }    
                }
                if(ct[cla].clase === "Activo" && ct[cla].corto[cc].cuenta === "IVA acreedor"){
                  civ = cc;                            
                }          
              }                        
            }
            
            var ccargo = `corto-Pasivo-Proveedores-Subcuenta-${csc}-${cpc}`
            var cabono = `corto-Activo-Almacén-Subcuenta-${sca}-${cpca}`        
            var cabIVA = `corto-Activo-IVA acreedor-Subcuenta-1-${civ}`

            console.log(ccargo, cabono, cabIVA)
            axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo: ccargo, abono: cabono, monto}, {withCredentials: true})
            .then(rpn => {
              console.log("Movimientos crédito--->",rpn)
            })
            .catch(err => console.log(err));

            axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo: ccargo, abono: cabIVA, monto: iva}, {withCredentials: true})
            .then(rpn => {
              console.log("Movimientos IVA crédito--->",rpn)
            })
            .catch(err => console.log(err));
          })             
          .catch(err => console.log(err));     
        }              
      })
      .catch(err => console.log(err))
        
      document.getElementById("selProdCh").value = "";
      document.getElementById("prodMas").value = "";        
      return
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  var alta = () => {
    var nombre    = document.getElementById("nombre").value;
    var marca     = document.getElementById("marca").value;
    var gramaje   = document.getElementById("gramaje").value;
    var costo     = document.getElementById("costo").value; 
    var cantidad  = document.getElementById("cantidad").value;
    var cred      = document.getElementById("credito").value;
    var valid     = fsCtas.map(c => c.subcuentas.map(sc => sc.subcuenta)).indexOf(marca);
    var ins       = ingr.map(i => i.nombre).indexOf(nombre);;
    var cuenta    = "corto-Activo-Almacén"
    var ctaCred   = "corto-Pasivo-Proveedores"

    
    if(nombre === "" || marca === "" || gramaje === "" || costo === "" || cantidad === ""){
      alert("Completa los campos de 'Alta de insumo'");
      return
    }

    fsCtas.forEach(cu => {
      cu.subcuentas.forEach(su => {
        if(marca === su.subcuenta){
          valid = su.subcuenta;
        }
      })
    })
    
    ingr.forEach(i => {
      if(nombre === i.nombre){        
        ins = i.nombre
      }
    })
    
    console.log(ins)

    if(valid !== "" && ins !== ""){
      alert("Ya existe el producto y proveedor!")
      return
    }

    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE ALTA? Nombre: ${nombre}; Marca: ${marca}; Gramaje: ${Num.format(gramaje)} Costo: ${MXN.format(costo)}; Cantidad: ${Num.format(cantidad)}`
    )){


    var tipo        = "Egreso";            
    var cuentaCargo = "corto-Activo-Bancos-Santander";                                    
    var cuentaAbono = "corto-Activo-Almacén-" + marca;            
    var concepto    = `Compra de insumos de cocina. Producto: ${nombre} de marca ${marca}`;
    var monto       = ((cantidad * costo) / gramaje) * 0.84;
    var iva         = ((cantidad * costo) / gramaje) * 0.16;
    var referencia  = "";
    var rfc         = "";
    var fecha       = new Date();
    var ctaCrgoCred = "corto-Pasivo-Proveedores-" + marca;                                        
    var ivaAcreCta  = "corto-Activo-IVA acreedor-IVA acreditable";                                    
    var ivaXacreCta = "corto-Activo-IVA acreedor-IVA por acreditar"; 

    if(valid === ""){
      axios.put(`${process.env.REACT_APP_API_URL}/subcuenta`, {id: cuenta, subcuenta: marca}, {withCredentials: true})
      .then(response => {                               
          console.log(response)

      if(cred === "Contado"){            
        axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo, cuentaAbono, concepto, monto, referencia, rfc, fecha}, {withCredentials: true})
        .then(r => {  
          console.log("Poliza contado---->", r)
        })              
        .catch(err => console.log(err));

        axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo, cuentaAbono: ivaAcreCta, concepto, monto: iva, referencia, rfc, fecha}, {withCredentials: true})
        .then(r => {  
          console.log("Poliza iva contado---->", r)
        })              
        .catch(err => console.log(err));

        axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
        .then(responseG => {
          var temp = [];
          var ct = responseG.data;                
          setCtas(ct);
          for(var d = 0; d < responseG.data.length; d++){                
            for(var c = 0; c < responseG.data[d].corto.length; c++){
              responseG.data[d].corto[c].plzo = "corto"
              responseG.data[d].corto[c].clase = responseG.data[d].clase
              temp.push(responseG.data[d].corto[c])                    
            }
            for(var l = 0; l < responseG.data[d].largo.length; l++){
              responseG.data[d].largo[l].plzo = "largo"
              responseG.data[d].largo[l].clase = responseG.data[d].clase
              temp.push(responseG.data[d].largo[l])
            }        
          }                 
          setFSctas(temp);

          var sc    = "";
          var pc    = "";
          var sca   = "";
          var pca   = "";
          var iv    = "";
          var iac   = "";         

        
          for(var cc = 0; cc < ct[0].corto.length; cc++){                                        
            if(ct[0].clase === "Activo" && ct[0].corto[cc].cuenta === "Bancos"){
              pc = cc
            }                    
            if(ct[0].clase === "Activo" && ct[0].corto[cc].cuenta === "Almacén"){
              pca = cc;
            }
            if(ct[0].clase === "Activo" && ct[0].corto[cc].cuenta === "IVA acreedor"){
              iv = cc;
            }
            for(var subC = 0; subC < ct[0].corto[cc].subcuentas.length; subC++){
              if(ct[0].corto[cc].subcuentas[subC].subcuenta === "Santander"){
                sc = subC;
              }
              if(ct[0].corto[cc].subcuentas[subC].subcuenta === marca){
                sca = subC;
              }
              if(ct[0].corto[cc].subcuentas[subC].subcuenta === "IVA acreditable"){
                iac = subC;
              }
            }
          }                        
          
          var cargo = `corto-Activo-Bancos-Santander-${sc}-${pc}`
          var abono = `corto-Activo-Almacén-Subcuenta-${sca}-${pca}`        
          var abIVA = `corto-Activo-IVA acreedor-Subcuenta-${iac}-${iv}`
          
          axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo, abono, monto}, {withCredentials: true})
          .then(rpn => {
            console.log("Movimientos de contado--->",rpn)
          })
          .catch(err => console.log(err));

          axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo, abono: abIVA, monto: iva}, {withCredentials: true})
          .then(rpn => {
            console.log("Movimientos IVA de contado--->",rpn)
          })
          .catch(err => console.log(err));
        })             
        .catch(err => console.log(err));     
      }

      })
      .catch(err => console.log(err))
    } 

    if(valid === "" && cred === "Credito"){
      axios.put(`${process.env.REACT_APP_API_URL}/subcuenta`, {id: ctaCred, subcuenta: marca}, {withCredentials: true})
      .then(response => {                               
          console.log(response)

      if(cred === "Credito"){            
        axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: ctaCrgoCred, cuentaAbono, concepto, monto, referencia, rfc, fecha}, {withCredentials: true})
        .then(r => {  
          console.log("Poliza credito---->", r)
        })              
        .catch(err => console.log(err));

        axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: ctaCrgoCred, cuentaAbono: ivaXacreCta, concepto, monto: iva, referencia, rfc, fecha}, {withCredentials: true})
        .then(r => {  
          console.log("Poliza iva credito---->", r)
        })              
        .catch(err => console.log(err));

        axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
        .then(responseG => {
          var temp = [];
          var ct = responseG.data;                
          setCtas(ct);
          for(var d = 0; d < responseG.data.length; d++){                
            for(var c = 0; c < responseG.data[d].corto.length; c++){
              responseG.data[d].corto[c].plzo = "corto"
              responseG.data[d].corto[c].clase = responseG.data[d].clase
              temp.push(responseG.data[d].corto[c])                    
            }
            for(var l = 0; l < responseG.data[d].largo.length; l++){
              responseG.data[d].largo[l].plzo = "largo"
              responseG.data[d].largo[l].clase = responseG.data[d].clase
              temp.push(responseG.data[d].largo[l])
            }        
          }                 
          setFSctas(temp);

          var csc    = "";
          var cpc    = "";
          var sca   = "";
          var cpca   = "";
          var civ    = "";                               

          for(var cla = 0; cla < ct.length; cla++){        
            for(var cc = 0; cc < ct[cla].corto.length; cc++){                                        
              if(ct[cla].clase === "Pasivo" && ct[cla].corto[cc].cuenta === "Proveedores"){
                cpc = cc;
                var prov = ct[cla].corto[cc].subcuentas;
                for(var p = 0; p < prov.length; p++){                
                  if(prov[p].subcuenta === marca){
                    csc = p;
                  }
                }                            
              }                    
              if(ct[cla].clase === "Activo" && ct[cla].corto[cc].cuenta === "Almacén"){
                cpca = cc;
                var alm = ct[cla].corto[cc].subcuentas;
                for(var a = 0; a < alm.length; a++){
                  if(alm[a].subcuenta === marca){
                    sca = a;
                  }
                }    
              }
              if(ct[cla].clase === "Activo" && ct[cla].corto[cc].cuenta === "IVA acreedor"){
                civ = cc;                            
              }          
            }                        
          }
          
          var ccargo = `corto-Pasivo-Proveedores-Subcuenta-${csc}-${cpc}`
          var cabono = `corto-Activo-Almacén-Subcuenta-${sca}-${cpca}`        
          var cabIVA = `corto-Activo-IVA acreedor-Subcuenta-1-${civ}`

          console.log(ccargo, cabono, cabIVA)
          axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo: ccargo, abono: cabono, monto}, {withCredentials: true})
          .then(rpn => {
            console.log("Movimientos crédito--->",rpn)
          })
          .catch(err => console.log(err));

          axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo: ccargo, abono: cabIVA, monto: iva}, {withCredentials: true})
          .then(rpn => {
            console.log("Movimientos IVA crédito--->",rpn)
          })
          .catch(err => console.log(err));
        })             
        .catch(err => console.log(err));     
      }  


      })
      .catch(err => console.log(err))
    }   
  

      axios.post(`${process.env.REACT_APP_API_URL}/ingrediente`, {nombre, gramaje, costo, marca, cantidad}, {withCredentials: true})
      .then(response => {
        document.getElementById("nombre").value = "";
        document.getElementById("marca").value = "";
        document.getElementById("gramaje").value = "";
        document.getElementById("costo").value = ""; 
        document.getElementById("cantidad").value = "";        
      })
      .catch(err => console.log(err))
      return
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  var altaClase = () => {
    var clase = document.getElementById("clase").value;
    
    if(clase === ""){
      alert("Completa los campos de 'Alta de insumo'");
      return
    }    

    var checkDupl = async() => {
      let fd = await axios.post(`${process.env.REACT_APP_API_URL}/repeatedClase`, {clase}, {withCredentials: true});      
      return fd
    }

    checkDupl()
    .then(resp => {
      console.log(resp)
      if(resp.data.length > 0){
        alert("Ya existe esa clase!")
        return
      }      
      if(window.confirm(
        `¿SEGURO QUE QUIERE DAR DE ALTA? Clase: ${clase}`
      )){
        axios.post(`${process.env.REACT_APP_API_URL}/cuentas`, {clase}, {withCredentials: true})
        .then(response => {                
          document.getElementById("clase").value = "";        
        })
        .catch(err => console.log(err))
        
        axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
        .then(response => {
          var temp = [];
          setCtas(response.data);
        for(var d = 0; d < response.data.length; d++){                
          for(var c = 0; c < response.data[d].corto.length; c++){
            response.data[d].corto[c].plzo = "corto"
            response.data[d].corto[c].clase = response.data[d].clase
            temp.push(response.data[d].corto[c])
          }
          for(var l = 0; l < response.data[d].largo.length; l++){
            response.data[d].largo[l].plzo = "largo"
            response.data[d].largo[l].clase = response.data[d].clase
            temp.push(response.data[d].largo[l])
          }        
        }                 
        setFSctas(temp);
        })
        return
      } else{
        console.log("Operación cancelada")
        return
      }
    })        
  }

  var altaCuenta = () => {
    var clase   = document.getElementById("refClase").value;    
    var plazo   = document.getElementById("plazo").value;
    var cuenta  = document.getElementById("cuenta").value;    
    var valid   = "";
    if(clase === "" || plazo === "" || cuenta === ""){
      alert("Completa los campos de 'Alta de cuenta'");
      return
    }

    fsCtas.forEach(cu => {
      if(cuenta === cu.cuenta){
        valid = cu.cuenta;
      }
    })

    if(cuenta === valid){
      alert("Ya existe la cuenta!")
      return
    }

    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE ALTA? Cuenta: ${cuenta}`
    )){
      axios.put(`${process.env.REACT_APP_API_URL}/cuenta`, {id: clase, plazo, cuenta}, {withCredentials: true})
      .then(response => {                        
        document.getElementById("plazo").value = "";        
        document.getElementById("cuenta").value = "";        
      })
      .catch(err => console.log(err))
       
       axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
      .then(response => {
        var temp = [];
        setCtas(response.data);
       for(var d = 0; d < response.data.length; d++){                
        for(var c = 0; c < response.data[d].corto.length; c++){
          response.data[d].corto[c].plzo = "corto"
          response.data[d].corto[c].clase = response.data[d].clase
          temp.push(response.data[d].corto[c])
        }
        for(var l = 0; l < response.data[d].largo.length; l++){
          response.data[d].largo[l].plzo = "largo"
          response.data[d].largo[l].clase = response.data[d].clase
          temp.push(response.data[d].largo[l])
        }        
      }                 
      setFSctas(temp);
      })
      return
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  var altaSubcuenta = () => {
    var cuenta    = document.getElementById("refCuentaS").value;
    var subcuenta = document.getElementById("subcuenta").value;
    var valid     = "";

    if(cuenta === "" || subcuenta === ""){
      alert("Completa los campos de 'Alta de insumo'");
      return
    }
    console.log(fsCtas)
    fsCtas.forEach(cu => {
      cu.subcuentas.forEach(su => {
        if(subcuenta === su.subcuenta && cu.cuenta === cuenta){
          valid = su.subcuenta;
        }
      })
    })

    if(subcuenta === valid){
      alert("La subcuenta ya existe!")
      return
    }

    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE ALTA? Subcuenta: ${subcuenta}`
    )){
      axios.put(`${process.env.REACT_APP_API_URL}/subcuenta`, {id: cuenta, subcuenta}, {withCredentials: true})
      .then(response => {                               
            document.getElementById("subcuenta").value = ""
      })
      .catch(err => console.log(err))
       
       axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
      .then(response => {
        var temp = [];
        setCtas(response.data);
        for(var d = 0; d < response.data.length; d++){                
          for(var c = 0; c < response.data[d].corto.length; c++){
            response.data[d].corto[c].plzo = "corto"
            response.data[d].corto[c].clase = response.data[d].clase
            temp.push(response.data[d].corto[c])
          }
          for(var l = 0; l < response.data[d].largo.length; l++){
            response.data[d].largo[l].plzo = "largo"
            response.data[d].largo[l].clase = response.data[d].clase
            temp.push(response.data[d].largo[l])
          }        
        }                 
        setFSctas(temp);
      })
      return
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  var altaPoliza = () => {                                                                                                                                                              
    var tipo        = document.getElementById("tipoPoliza").value;
    var cuentaCargo = document.getElementById("refCuentaCargo").value;
    var cuentaAbono  = document.getElementById("refCuentaAbono").value;
    var concepto    = document.getElementById("conceptoP").value;
    var monto       = document.getElementById("montoP").value;
    var referencia  = document.getElementById("referenciaP").value;
    var rfc         = document.getElementById("rfcP").value;
    var fecha       = document.getElementById("fechaMovP").value;
    
    if(tipo === "" || cuentaCargo === "" || cuentaAbono === "" || concepto === "" || monto === "" || referencia === "" || rfc === "" || fecha === ""){
      alert("Completa los campos de 'Alta de poliza'");
      return
    }

    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE ALTA? Cargo: ${cuentaCargo} Abono: ${cuentaAbono} Concepto: ${concepto}`
    )){
      axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo, cuentaAbono, concepto, monto, referencia, rfc, fecha}, {withCredentials: true})
      .then(response => {    
          axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo: cuentaCargo, abono: cuentaAbono, monto}, {withCredentials: true})
          .then(resp => {
            document.getElementById("tipoPoliza").value = ""
            document.getElementById("refCuentaCargo").value = ""
            document.getElementById("refCuentaAbono").value = ""
            document.getElementById("conceptoP").value = ""
            document.getElementById("montoP").value = ""
            document.getElementById("referenciaP").value = ""
            document.getElementById("rfcP").value = ""
            document.getElementById("fechaMovP").value = ""
          })
          .catch(err => console.log(err));          
      })
      .catch(err => console.log(err))
       
       axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
      .then(response => {
        var temp = [];
        setCtas(response.data);
        for(var d = 0; d < response.data.length; d++){                
        for(var c = 0; c < response.data[d].corto.length; c++){
          response.data[d].corto[c].plzo = "corto"
          response.data[d].corto[c].clase = response.data[d].clase
          temp.push(response.data[d].corto[c])
        }
        for(var l = 0; l < response.data[d].largo.length; l++){
          response.data[d].largo[l].plzo = "largo"
          response.data[d].largo[l].clase = response.data[d].clase
          temp.push(response.data[d].largo[l])
        }        
      }                 
      setFSctas(temp);
      })
      return
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  var filtroNom = () => {    
    var v = {
      nom: document.getElementById('nom').value.toUpperCase(), 
      marc: document.getElementById('marc').value.toUpperCase(),       
      ex: document.getElementById('ex').value
    }    
    var res = ingr.filter(i => i.nombre.toUpperCase().includes(v.nom) && i.marca.toUpperCase().includes(v.marc) && (v.ex === 'Todo' ? Math.sign(i.cantidad) > -2 : Math.sign(i.cantidad) === parseInt(v.ex)))
    
    setFilingr(res);
  }

  var filtroClass = () => {    
    var v = {
      clasA: document.getElementById('filClassA').value.toUpperCase(), 
      clasB: document.getElementById('filClassB').value.toUpperCase(), 
      ctaA: document.getElementById('filCtaA').value.toUpperCase(),      
      ctaB: document.getElementById('filCtaB').value.toUpperCase(),       
      fecha: new Date(document.getElementById('filFecha').value).toUTCString().slice(5,16)
    }        
    var res = polizas.filter(i => i.claseAbono.toUpperCase().includes(v.clasA) && i.claseCargo.toUpperCase().includes(v.clasB) && i.cuentaAbono.toUpperCase().includes(v.ctaA) && i.cuentaCargo.toUpperCase().includes(v.ctaB))
    if(v.fecha !== ""){      
       res = polizas.filter(i => i.claseAbono.toUpperCase().includes(v.clasA) && i.claseCargo.toUpperCase().includes(v.clasB) && i.cuentaAbono.toUpperCase().includes(v.ctaA) && i.cuentaCargo.toUpperCase().includes(v.ctaB) && (new Date(i.fecha).toUTCString().slice(5,16)) === v.fecha)      
    }
    setFilPol(res);
  }

  return(
    <div style={{marginBottom: '0', fontFamily: 'Roxborough'}}>
      <div style={{margin: '20px'}}>
        <button style={{width: '50%',backgroundColor: cocina === 'block' ? 'rgb(52,67,86)' : 'rgb(197,221,226)', fontWeight: 'bold', fontSize: '25px', padding: '5px', border: 'none', cursor: 'pointer', color: cocina === 'block' ? 'white' : 'black'}} onClick={() => {setCocina('block'); setFact('none'); setCuenta('none'); setPoliza('none')}}>Cocina</button>
        <button style={{width: '50%',backgroundColor: factura=== 'block' ? 'rgb(52,67,86)' : 'rgb(197,221,226)', fontWeight: 'bold', fontSize: '25px', padding: '5px', border: 'none', cursor: 'pointer', color: factura === 'block' ? 'white' : 'black'}} onClick={() => {setCocina('none'); setFact('block'); setCuenta('none'); setPoliza('none')}}>Facturas</button>
        <button style={{width: '50%',backgroundColor: cuenta=== 'block' ? 'rgb(52,67,86)' : 'rgb(197,221,226)', fontWeight: 'bold', fontSize: '25px', padding: '5px', border: 'none', cursor: 'pointer', color: cuenta === 'block' ? 'white' : 'black'}} onClick={() => {setCocina('none'); setFact('none'); setCuenta('block'); setPoliza('none')}}>Cuentas</button>
        <button style={{width: '50%',backgroundColor: poliza=== 'block' ? 'rgb(52,67,86)' : 'rgb(197,221,226)', fontWeight: 'bold', fontSize: '25px', padding: '5px', border: 'none', cursor: 'pointer', color: poliza === 'block' ? 'white' : 'black'}} onClick={() => {setCocina('none'); setFact('none'); setCuenta('none'); setPoliza('block')}}>Pólizas</button>
      </div>

      <div style={{display: cocina}}>
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Alta de insumo</h3>
          <p>Alta de productos nuevos o proveedores nuevos</p>
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Nombre de producto:</th>
                  <th>Marca / Proveedor de producto:</th>
                  <th>Unidad de compra(gr/ml):</th>
                  <th>Costo por unidad:</th>
                  <th>Cantidad de alta (gr/ml):</th>
                  <th>Pago</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" id='nombre' placeholder='Nombre'/></td>
                  <td><input type="text" id='marca' placeholder='Marca y proveedor'/></td>
                  <td><input type="number" id='gramaje' placeholder='Unidad'/></td>
                  <td><input type="number" id='costo' placeholder='Costo'/></td>
                  <td><input type="number" id='cantidad' placeholder='Cantidad'/></td>
                  <td>
                    <select id="credito">
                      <option value="Credito">Crédito</option>
                      <option value="Contado">Contado</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{textAlign: 'right', margin: '50px'}}>
            <button style={{width: '100%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}} onClick={() => alta()}>Dar de alta</button>         
          </div>            
        </div>
        <hr />
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Agregar existencias</h3>
          <p>Agrega existencias de insumos dados de alta previamente</p>
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Agregar cantidad</th>
                  <th>Pago</th>                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select id='selProdCh'>
                      {ingr ? ingr.map((i, idx) => {
                        return(
                          <option key={idx} value={i._id}>{i.nombre} - {i.marca}</option>
                        )
                      }) : null}
                    </select>
                  </td>
                  <td><input id='prodMas' type="number" min={0}/></td>
                  <td>
                    <select id="cred">
                      <option value="Credito">Crédito</option>
                      <option value="Contado">Contado</option>
                    </select>
                  </td>
                </tr>
              </tbody>             
            </table>
          </div>
          <div style={{textAlign: 'right', margin: '50px'}}>
            <button style={{width: '100%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}} onClick={() => altaCant()}>Agregar cantidad</button>         
          </div>                   
        </div>
        <hr />
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Inventario</h3>
          <p>Filtros</p>        
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por nombre: </label>
            <input id='nom' onChange={() => filtroNom()} type="text" placeholder='Nombre'/>
          </div>
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por proveedor o marca: </label>
            <input id='marc' onChange={() => filtroNom()} type="text" placeholder='Proveedor - Marca'/>
          </div>       
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por existencias: </label>
            <select id='ex' onChange={() => filtroNom()}>
              <option value="Todo">Todo</option>
              <option value='0'>En cero</option>
              <option value="1">Positivas</option>
              <option value="-1">Negativas</option>
            </select>        
          </div>  
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Marca/Proveedor</th>
                  <th>Unidad en (gr)</th>
                  <th>Existencia (gr)</th>
                  <th>Costo</th>
                </tr>
              </thead>
              <tbody>
                {filIngr ? filIngr.map((i, idx) => {
                  return(
                    <tr key={idx}>
                      <td>{i.nombre}</td>
                      <td>{i.marca}</td>
                      <td>{Num.format(i.gramaje)}</td>
                      <td>{Num.format(i.cantidad)}</td>
                      <td>{MXN.format(i.costo)}</td>
                    </tr>
                  )
                }): null}
              </tbody>
            </table>        
          </div>
        </div>
      </div>

      <div style={{display: cuenta}}>
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Alta de clase contable</h3>
          <p>Alta de una clase nueva dentro del catálogo de cuentas existente. (Activo, Pasivo, Etc.)</p>
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Clase:</th>                                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>                    
                    <select id="clase">                      
                      <option value="Activo">Activo</option>
                      <option value="Pasivo">Pasivo</option>
                      <option value="Capital">Capital</option>
                      <option value="Cuentas complementarias de activo">Cuentas complementarias de activo</option>
                      <option value="Cuentas de resultados deudoras (gastos)">Cuentas de resultados deudoras (gastos)</option>
                      <option value="Cuentas de resultados acreedoras (ingresos)">Cuentas de resultados acreedoras (ingresos)</option>
                      <option value="Cuentas de orden">Cuentas de orden</option>
                    </select>
                  </td>                  
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{textAlign: 'right', margin: '50px'}}>
            <button style={{width: '100%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}} onClick={() => altaClase()}>Dar de alta</button>         
          </div>            
        </div>
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Alta de cuenta contable</h3>
          <p>Alta de una cuenta nueva dentro del catálogo de cuentas existente. (Bancos, Caja, Clientes, Etc.)</p>
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Clase:</th>
                  <th>Cuenta:</th>                                  
                  <th>Plazo:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select id="refClase">
                      {ctas && ctas.map(cta => {                        
                        return(
                          <option key={cta._id} value={cta._id}>{cta.clase}</option>                          
                        )
                      })}
                    </select>
                  </td>
                  <td><input type="string" id='cuenta' placeholder='Nombre'/></td>
                  <td>
                     <select id='plazo'>
                      <option value="corto">Circulante / Corto plazo</option>
                      <option value="largo">No circulante / Largo plazo / Capital contable</option>
                    </select>
                  </td>                  
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{textAlign: 'right', margin: '50px'}}>
            <button style={{width: '100%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}} onClick={() => altaCuenta()}>Dar de alta</button>         
          </div>            
        </div>
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Alta de subcuenta contable</h3>
          <p>Alta de una subcuenta nueva dentro del catálogo de cuentas existente.</p>
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Cuenta:</th>
                  <th>Subcuenta:</th>                                  
                </tr>
              </thead>
              <tbody>
                <tr>
                   <td>                    
                    <select id="refCuentaS">
                      {fsCtas && fsCtas.map(cta => {                        
                        return(
                          <option key={cta._id} value={`${cta.plzo}-${cta.clase}-${cta.cuenta}`}>{cta.cuenta}</option> 
                        )
                      })}
                    </select>
                  </td>                  
                  <td><input type="string" id='subcuenta' placeholder='Nombre'/></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{textAlign: 'right', margin: '50px'}}>
            <button style={{width: '100%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}} onClick={() => altaSubcuenta()}>Dar de alta</button>         
          </div>            
        </div>
        <div style={{textAlign: 'left', padding: '10px', width: '70%', margin: 'auto'}}>        
          {ctas && ctas.map(cta => {              
            return(
              <div key={cta._id}>              
                <h2>
                  {cta.clase}
                </h2>                
                {cta.clase === "Activo" ? <h3>Activo circulante</h3> : cta.clase === "Pasivo" ? <h3>Pasivo a corto plazo</h3> : null}                                                                                                          
                {cta.corto && cta.corto.map(c => {          
                    var sum = c.subcuentas.reduce((acc, curr) =>  acc + curr.monto, 0)
                    return(
                      <div key={c._id}>
                        <div style={{columnCount: 2, fontWeight: 'bold'}}>
                          <p style={{margin: 'auto', columnWidth: '80%'}}>{c.cuenta}</p>
                          <p style={{margin: 'auto', textAlign: 'right', columnWidth: '20%'}}>{MXN.format(sum)}</p>
                        </div>                        
                        {c.subcuentas.map(sc => {                          
                          return(
                            <div key={sc._id} style={{columnCount: 2, paddingLeft: '2rem'}}>
                              <p style={{margin: 'auto', columnWidth: '80%'}}>{sc.subcuenta}</p>
                              <p style={{margin: 'auto', textAlign: 'right', columnWidth: '20%'}}>{MXN.format(sc.monto)}</p>
                            </div>
                          )
                        })}
                      </div>
                    )
                })}
                {cta.clase === "Activo" ? <h3>Activo no circulante</h3> : cta.clase === "Pasivo" ? <h3>Pasivo a largo plazo</h3> : null}
                {cta.largo && cta.largo.map(l => {
                  var sum = l.subcuentas.reduce((acc, curr) =>  acc + curr.monto, 0)
                    return(
                      <div key={l._id}>
                        <div style={{columnCount: 2, fontWeight: 'bold'}}>
                          <p style={{margin: 'auto', columnWidth: '80%'}}>{l.cuenta}</p>
                          <p style={{margin: 'auto', textAlign: 'right', columnWidth: '20%'}}>{MXN.format(sum)}</p>
                        </div>                        
                        {l.subcuentas.map(sc => {
                          return(
                            <div key={sc._id} style={{columnCount: 2, paddingLeft: '2rem'}}>
                              <p style={{margin: 'auto', columnWidth: '80%'}}>{sc.subcuenta}</p>
                              <p style={{margin: 'auto', textAlign: 'right', columnWidth: '20%'}}>{MXN.format(sc.monto)}</p>
                            </div>
                          )
                        })}
                      </div>
                    )
                })}
              </div>                   
            )
          })}              
        </div>
      </div>

      <div style={{display: poliza}}>           
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Alta de póliza contable</h3>
          <p>Alta de una póliza que registre un movimiento contable.</p>
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Tipo de póliza:</th>
                  <th>Cuenta cargo:</th>
                  <th>Cuenta abono:</th> 
                  <th>Concepto:</th>
                  <th>Monto:</th>
                  <th>Referencia:</th>
                  <th>RFC:</th>
                  <th>Fecha:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select id="tipoPoliza">
                      <option value="Ingreso">Ingreso</option>
                      <option value="Egreso">Egreso</option>
                      <option value="Diario">Diario</option>
                      <option value="Traspaso">Traspaso</option>
                      <option value="Depreciacion">Depreciación</option>
                      <option value="Ajuste">Ajuste</option>
                    </select>
                  </td>
                   <td>                    
                    <select id="refCuentaCargo">
                      {fsCtas && fsCtas.map(cta => {                        
                        return(
                          cta.subcuentas.map((sc, idx) => {
                            return(
                              <option key={idx} value={`${cta.plzo}-${cta.clase}-${cta.cuenta}-${sc.subcuenta}-${idx}-${cta.pos}`}>{sc.subcuenta}</option> 
                            )
                          })                          
                        )
                      })}
                    </select>
                  </td>                  
                  <td>
                    <select id="refCuentaAbono">
                      {fsCtas && fsCtas.map(cta => {                                                
                        return(
                          cta.subcuentas.map((sc, idx) => {
                            return(
                              <option key={idx} value={`${cta.plzo}-${cta.clase}-${cta.cuenta}-${sc.subcuenta}-${idx}-${cta.pos}`}>{sc.subcuenta}</option> 
                            )
                          })                          
                        )
                      })}
                    </select>
                  </td>
                  <td><input type="string" id='conceptoP' placeholder='Concepto'/></td>
                  <td><input type="number" id='montoP' placeholder='Monto'/></td>
                  <td><input type="string" id='referenciaP' placeholder='Documento (CFDI u otro)'/></td>
                  <td><input type="string" id='rfcP' placeholder='RFC'/></td>
                  <td><input type="date" id='fechaMovP' placeholder={new Date()}/></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{textAlign: 'right', margin: '50px'}}>
            <button style={{width: '100%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}} onClick={() => altaPoliza()}>Dar de alta</button>         
          </div>            
        </div>
         <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Polizas</h3>
          <p>Filtros</p>        
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por clase abono: </label>
            <input id='filClassA' onChange={() => filtroClass()} type="text" placeholder='Clase'/>
          </div>
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por clase cargo: </label>
            <input id='filClassB' onChange={() => filtroClass()} type="text" placeholder='Clase'/>
          </div>
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por cuenta abono: </label>
            <input id='filCtaA' onChange={() => filtroClass()} type="text" placeholder='Cuenta'/>
          </div>      
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por cuenta cargo: </label>
            <input id='filCtaB' onChange={() => filtroClass()} type="text" placeholder='Cuenta'/>
          </div>       
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por fecha: </label>
            <input id='filFecha' onChange={() => filtroClass()} type='date'/>
          </div>  
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Concepto</th>
                  <th>Clase Cargo</th>
                  <th>Clase Abono</th>
                  <th>Cuenta Cargo</th>
                  <th>Cuenta Abono</th>
                  <th>{MXN.format(filPol.reduce((acc, curr) => acc + curr.monto, 0))}</th>
                </tr>
              </thead>
              <tbody>
                {filPol ? filPol.map((i, idx) => {
                  var f = new Date(i.fecha).toUTCString().slice(5, 16)                                  
                  return(
                    <tr key={idx}>
                      <td>{f}</td>
                      <td>{i.concepto}</td>
                      <td>{i.claseCargo}</td>
                      <td>{i.claseAbono}</td>
                      <td>{i.cuentaCargo}</td>
                      <td>{i.cuentaAbono}</td>
                      <td>{MXN.format(i.monto)}</td>
                    </tr>
                  )
                }): null}
              </tbody>
            </table>        
          </div>
        </div>        
      </div>

      <div style={{display: factura}}>
        <h1>Alta de todas las cervezas en facturas</h1>
          <button onClick={() => addBeer()} style={{width: '20%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}}>Dar de alta cervezas</button>
        <h1>Alta Facturas</h1>              
          <div style={{
            borderRadius: '5px',
            backgroundColor: '#f2f2f2',
            padding: '20px',
          }}>
            <button onClick={() => addFact()} style={{width: '20%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}}>Dar de alta factura</button>
            <div style={{margin: '10px', fontWeight: '900'}}>Datos Generales</div>
            <table style={{width: '80%', border: '1px solid white', borderCollapse: 'collapse', margin: 'auto', fontSize: '.8rem'}}>
              <thead>
                <tr>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Folio</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Proveedor</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Entrega</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Crédito (días)</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Valor (incluye IVA)</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>IVA</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>IEPS</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}># Cervezas</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Pago</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Estatus</th>                
                </tr>
              </thead>   
              <tbody>
                {gen ? 
                <tr>
                  <td>{gen.folio}</td>
                  <td>{gen.proveedor}</td>
                  <td>{gen.entrega ? gen.entrega.getDate() + "/" + gen.entrega.getMonth() + "/" +gen.entrega.getFullYear() : gen.entrega}</td>
                  <td>{gen.credito}</td>
                  <td>{!isNaN(gen.valor) ? MXN.format(gen.valor) : null}</td>
                  <td>{!isNaN(gen.iva) ? MXN.format(gen.iva) : null}</td>
                  <td>{!isNaN(gen.ieps) ? MXN.format(gen.ieps) : null}</td>
                  <td>{gen.numCervezas}</td>
                  <td>{gen.pago}</td>
                  <td>{gen.estatusPago}</td>
                </tr> : null}                
              </tbody>                         
            </table>
            <div style={{margin: '10px', fontWeight: '900'}}>Cervezas</div>
            <table style={{width: '80%', border: '1px solid white', borderCollapse: 'collapse', margin: 'auto', fontSize: '.8rem'}}>
              <thead>
                <tr>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Nombre</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Cervecera</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Estilo</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Descripción</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Tipo</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Precio</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Costo</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>IVA</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>IEPS</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Cantidad</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>País</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Región</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>ABV</th>                   
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>IBU</th>                                     
                </tr>
              </thead>
              <tbody>                
                {beer ? beer.map((b, idx) => {
                  var color = idx % 2 ? {backgroundColor: 'rgb(197,221,226)'} : {backgroundColor: 'white'}
                  return(
                    <tr key={idx} style={color}>
                      <td>{b.nombre}</td>
                      <td>{b.cervecera}</td>
                      <td>{b.estilo}</td>        
                      <td>{b.descripcion}</td>
                      <td>{b.tipo}</td>                      
                      <td>{MXN.format(b.precio)}</td>
                      <td>{MXN.format(b.costo)}</td>
                      <td>{MXN.format(b.iva)}</td>
                      <td>{MXN.format(b.ieps)}</td>
                      <td>{b.cantidad}</td>        
                      <td>{b.pais}</td>
                      <td>{b.region}</td>
                      <td>{b.abv}%</td>
                      <td>{b.ibu}</td>                                          
                    </tr>
                  )
                }) : null}                
              </tbody>
            </table>
            <div style={{margin: '10px', fontWeight: '900'}}>Merch</div>
            <table style={{width: '80%', border: '1px solid white', borderCollapse: 'collapse', margin: 'auto', fontSize: '.8rem'}}>
              <thead>
                <tr>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Producto</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Marca</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Costo</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Capacidad</th>
                  <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {merch ? merch.map((b, idx) => {
                  var color = idx % 2 ? {backgroundColor: 'rgb(197,221,226)'} : {backgroundColor: 'white'}
                  return(
                    <tr key={idx} style={color}>
                      <td>{b.producto}</td>                      
                      <td>{b.marca}</td>
                      <td>{MXN.format(b.costo)}</td>
                      <td>{b.capacidad}</td>        
                      <td>{b.cantidad}</td>                                                                                   
                    </tr>                   
                  )
                }) : null}                
              </tbody>
            </table>            
          </div> 
        </div>           
    </div>
  )
}

export default Altas;
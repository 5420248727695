import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Gastos () {
  const [gastos, setGastos]   = useState([]);  
  const [filGas, setFilGas]   = useState([]); 
  const [month]               = useState(["ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic"]);
  
  const MXN = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});  

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/gastos`, {withCredentials: true})
    .then(response => {
      setGastos(response.data);
      setFilGas(response.data);     
    })
    .catch(err => {console.log(err)})
  }, [])

  var filtroNom = () => { 
    var f = new Date(document.getElementById('fecha').value);
    var dia = f.getUTCDate()
    var mes = f.getUTCMonth()
    var año = f.getUTCFullYear()
    
    var v = {
      desc: document.getElementById('desc').value.toUpperCase(), 
      fecha: dia + "/" + month[mes] + "/" + año,       
      doc: document.getElementById('doc').value
    }
     
    var res = gastos.filter(i => isNaN(dia) ? (i.descripcion.toUpperCase().includes(v.desc) && (v.doc === 'Todo' ? i.documento.includes("t") : i.documento === v.doc)) : (i.descripcion.toUpperCase().includes(v.desc) && ((new Date(i.fecha).getDate()) + "/" + month[(new Date(i.fecha).getMonth())] + "/" + (new Date(i.fecha).getFullYear())) === v.fecha && (v.doc === 'Todo' ? i.documento.includes("t") : i.documento === v.doc)))
    
    setFilGas(res);
  }

  var alta = () => {
    var documento = document.getElementById("documento").value;
    var numero = document.getElementById("numero").value;
    var descripcion = document.getElementById("descripcion").value;
    var monto = document.getElementById("monto").value; 
    var fecha = document.getElementById("fechaAlta").value;    
    
    if(documento === "" || numero === "" || descripcion === "" || monto === "" || fecha === ""){
      alert("Completa los campos de 'Alta de gasto'");
      return
    }

    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE ALTA? Documento: ${documento}; Número: ${numero}; Monto: ${MXN.format(monto)}; Fecha: ${fecha}; Descripción: ${descripcion}`
    )){
      axios.post(`${process.env.REACT_APP_API_URL}/gasto`, {documento, numero, descripcion, monto, fecha}, {withCredentials: true})
      .then(response => {
        document.getElementById("documento").value = "";
        document.getElementById("numero").value = "";
        document.getElementById("descripcion").value = "";
        document.getElementById("monto").value = ""; 
        document.getElementById("fechaAlta").value = "";
        console.log(response);
      })
      .catch(err => console.log(err))
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  return(
    <div style={{marginBottom: '0', fontFamily: 'Roxborough'}}>
      <div style={{textAlign: 'left', padding: '10px'}}>
        <h3>Alta de gasto</h3>
        <p>Alta de gastos realizados en el día.</p>
        <div style={{overflowX: 'auto'}}>
          <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
            <thead>
              <tr>
                <th>Documento:</th>
                <th>Numero:</th>
                <th>Descripción:</th>
                <th>Monto:</th>
                <th>Fecha:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select id='documento'>                    
                    <option value='Factura'>Factura</option>
                    <option value="Ticket">Ticket</option>
                    <option value="Nota">Nota</option>
                    <option value="Otro">Otro</option>
                  </select>                   
                </td>
                <td><input type="text" id='numero' placeholder='Número de documento'/></td>
                <td><input type="text" id='descripcion' placeholder='Descripción'/></td>
                <td><input type="number" id='monto' placeholder='Monto'/></td>
                <td><input type="date" id='fechaAlta' placeholder='Fecha'/></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{textAlign: 'right'}}>
          <button onClick={() => alta()}>Dar de alta</button>         
        </div>            
      </div> 
       <hr />
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Lista de gastos</h3>
          <p>Filtros</p>        
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por descripción: </label>
            <input id='desc' onChange={() => filtroNom()} type="text" placeholder='Palabra clave'/>
          </div>
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por fecha: </label>
            <input id='fecha' onChange={() => filtroNom()} type="date" placeholder='Fecha'/>
          </div>       
          <div style={{textAlign: 'left', margin: '10px'}}>
            <label>Por documento: </label>
            <select id='doc' onChange={() => filtroNom()}>
              <option value="Todo">Todo</option>
              <option value='Factura'>Factura</option>
              <option value="Ticket">Ticket</option>
              <option value="Nota">Nota</option>
              <option value="Otro">Otro</option>
            </select>        
          </div>  
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Documento</th>
                  <th>Número</th>
                  <th>Descripción</th>
                  <th>Fecha</th>
                  <th>Monto</th>
                </tr>
              </thead>
              <tbody>
                {filGas ? filGas.map((i, idx) => {
                  var fecha = new Date(i.fecha);
                  var dia = fecha.getUTCDate()
                  var mes = fecha.getUTCMonth()
                  var año = fecha.getUTCFullYear()
                  return(
                    <tr key={idx}>
                      <td>{i.documento}</td>
                      <td>{i.numero}</td>
                      <td>{i.descripcion}</td>
                      <td>{dia}/{month[mes]}/{año}</td>
                      <td>{MXN.format(i.monto)}</td>
                    </tr>
                  )
                }): null}
              </tbody>
            </table>        
          </div>
        </div>
    </div>
  )
}

export default Gastos;
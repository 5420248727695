import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './auth/Context';

function Navbar () {
  const locaction             = useLocation();  
  const authContext           = useContext(AuthContext);
  const [userRole, setRole]   = useState({role: ""});
  const [matches, setMatches] = useState(window.matchMedia('screen and (min-width: 1100px)'));
  const [mobile, setMobile]   = useState(window.matchMedia('(max-width: 400px)'));  
  const [listyle, setLi]      = useState({
    listStyle: 'none', 
    padding: '5px 14px'
  })
  const [hamb, setHamb]       = useState({
    cursor: 'pointer',
    float: 'right',
    padding: '50px 60px',
  }) 
  const [navC, setNav]        = useState({
    width: '100%',
    height: '100%',
    position: 'fixed',
    overflow: 'hidden',              
    backgroundColor: 'rgb(52, 67, 86)',
    color: '#fff',
    maxHeight: '0',
    transition: 'maxHeight .5s ease-out'
  })
  const [before, setBefore]   = useState({
    background: 'white',
    content: '',
    display: 'block',
    height: '2px',
    position: 'relative',
    transition: 'all .2s ease-out',            
    top: '8px'
  })
  const [after, setAfter]     = useState({
    background: 'white',            
    display: 'block',
    height: '2px',
    position: 'relative',
    transition: 'all .2s ease-out',            
    top: '-8px'    
  })
  const [mid, setMid]         = useState({
    background: 'white',
    display: 'block',
    height: '2px', 
    position: 'relative',             
    width: '24px',     
  })

  useEffect(() => {
    if(locaction.pathname === '/carta'){
      //change background
    }
  })

  useEffect(() => {    
    if(matches.matches) {
        setNav({
          maxHeight: 'none',
          top: '0',
          position: 'relative',
          float: 'right',
          width: 'fit-content',
          backgroundColor: 'transparent',
        })
        setHamb({
          display: 'none'
        })
        setLi({
          listStyle: 'none',
          float: 'left',
        })        
      } else if(mobile.matches){
          setNav({
            width: '100%',
            height: '100%',
            position: 'fixed',
            overflow: 'hidden',              
            backgroundColor: 'rgb(52, 67, 86)',
            color: '#fff',
            maxHeight: '0',
            transition: 'maxHeight .5s ease-out'
          })
          setHamb({
            cursor: 'pointer',
            float: 'right',
            padding: '50px 60px',
            position: 'absolute',
            top: '10px'
          }) 
          setLi({
            listStyle: 'none', 
            padding: '5px 5px'
          }) 
      } else{
        setNav({
          width: '100%',
          height: '100%',
          position: 'fixed',
          overflow: 'hidden',              
          backgroundColor: 'rgb(52, 67, 86)',
          color: '#fff',
          maxHeight: '0',
          transition: 'maxHeight .5s ease-out'
        })
        setHamb({
          cursor: 'pointer',
          float: 'right',
          padding: '50px 60px',
        }) 
        setLi({
          listStyle: 'none', 
          padding: '5px 14px'
        })       
      }
    matches.onchange = (e) => {      
      if(e.matches) {
        setNav({
          maxHeight: 'none',
          top: '0',
          position: 'relative',
          float: 'right',
          width: 'fit-content',
          backgroundColor: 'transparent',
        })
        setHamb({
          display: 'none'
        })
        setLi({
          listStyle: 'none',
          float: 'left'
        })        
      } else{
        setNav({
          width: '100%',
          height: '100%',
          position: 'fixed',
          overflow: 'hidden',              
          backgroundColor: 'rgb(52, 67, 86)',
          color: '#fff',
          maxHeight: '0',
          transition: 'maxHeight .5s ease-out'
        })
        setHamb({
          cursor: 'pointer',
          float: 'right',
          padding: '50px 60px',
        }) 
        setLi({
          listStyle: 'none', 
          padding: '5px 14px'
        })       
      }
    }
    setRole(authContext.isLoggedIn.loggedInUser);
  }, [matches, authContext])

  var logout = (e) => {
    e.preventDefault();       

    authContext.logout()    
    .then(response => {      
      authContext.user(response)      
      window.location.replace(`/carta`)      
    })
    .catch(err => {      
      console.log(err)
    })
    return
  
  }

  var roles = () => {    
    var r = [
      {
        role: "ADMIN",
        nav: 
          <div style={{fontSize: '18px', zIndex: '0', textAlign: 'center', fontFamily: 'proxima-nova', fontWeight: 'bold'}}>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href="/carta"><span>menú</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href="/cocina"><span>cocina</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href="/gastos"><span>gastos</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href="/tickets"><span>tickets</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href="/facturas"><span>entradas</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href="/altas"><span>altas</span></a></li>                    
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href="/ventas"><span>ventas</span></a></li>  
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href="/usuarios"><span>usuarios</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '20px'}} href={`/perfil/${userRole._id}`}><span>perfil</span></a></li>
              <li style={listyle}><button style={{cursor: "pointer", fontSize: '18px', border: 'none', fontFamily: 'proxima-nova', fontWeight: 'bold', backgroundColor: "transparent", color: "#fff", padding: '20px', textDecoration: 'none'}} onClick={(e) => logout(e)}>logout</button></li>
            </div> 
      },
      {
        role: "COCINA",
        nav: 
          <div style={{fontSize: '18px', zIndex: '0', textAlign: 'center', fontFamily: 'proxima-nova', fontWeight: 'bold'}}>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/carta"><span>menú</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/cocina"><span>cocina</span></a></li>  
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href={`/perfil/${userRole._id}`}><span>perfil</span></a></li>                         
              <li style={listyle}><button style={{cursor: "pointer", fontSize: '18px', border: 'none', fontFamily: 'proxima-nova', fontWeight: 'bold', backgroundColor: "transparent", color: "#fff", padding: '30px', textDecoration: 'none'}} onClick={(e) => logout(e)}>logout</button></li>
            </div> 
      },
      {
        role: "PISO",
        nav: 
          <div style={{fontSize: '18px', zIndex: '0', textAlign: 'center', fontFamily: 'proxima-nova', fontWeight: 'bold'}}>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/carta"><span>menú</span></a></li>              
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/tickets"><span>tickets</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href={`/perfil/${userRole._id}`}><span>perfil</span></a></li>              
              <li style={listyle}><button style={{cursor: "pointer", fontSize: '18px', border: 'none', fontFamily: 'proxima-nova', fontWeight: 'bold', backgroundColor: "transparent", color: "#fff", padding: '30px', textDecoration: 'none'}} onClick={(e) => logout(e)}>logout</button></li>
            </div> 
      },
      {
        role: "BARRA",
        nav: 
          <div style={{fontSize: '18px', zIndex: '0', textAlign: 'center', fontFamily: 'proxima-nova', fontWeight: 'bold'}}>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/carta"><span>menú</span></a></li>              
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/tickets"><span>tickets</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href={`/perfil/${userRole._id}`}><span>perfil</span></a></li>              
              <li style={listyle}><button style={{cursor: "pointer", fontSize: '18px', border: 'none', fontFamily: 'proxima-nova', fontWeight: 'bold', backgroundColor: "transparent", color: "#fff", padding: '30px', textDecoration: 'none'}} onClick={(e) => logout(e)}>logout</button></li>
            </div> 
      },
      {
        role: "CLIENTE",
        nav: 
          <div style={{fontSize: '18px', zIndex: '0', textAlign: 'center', fontFamily: 'proxima-nova', fontWeight: 'bold'}}>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/carta"><span>menú</span></a></li>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href={`/perfil/${userRole._id}`}><span>perfil</span></a></li>
              <li style={listyle}><button style={{cursor: "pointer", fontSize: '18px', border: 'none', fontFamily: 'proxima-nova', fontWeight: 'bold', backgroundColor: "transparent", color: "#fff", padding: '30px', textDecoration: 'none'}} onClick={(e) => logout(e)}>logout</button></li>
            </div> 
      },
      {
        role: "",
        nav: 
          <div style={{fontSize: '18px', zIndex: '0', textAlign: 'center', fontFamily: 'proxima-nova', fontWeight: 'bold'}}>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/carta"><span>menú</span></a></li>              
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/signup"><span>login</span></a></li>                    
            </div> 
      }
    ]
    var userPos = r.map(u => u.role).indexOf(userRole.role);      
    return r[userPos].nav;
  }

  var check = (e) => {
    if(e.target.checked){
      setNav({
        width: '100%',
        height: '100%',
        position: 'fixed',
        overflow: 'hidden',              
        backgroundColor: 'rgba(52, 67, 86)',
        color: '#fff',        
        transition: 'maxHeight .5s ease-out'
      })
      setBefore({
        background: 'white',
        content: '',
        display: 'block',
        height: '2px',
        position: 'relative',
        transition: 'all .2s ease-out',            
        top: '4px',
        transform: 'rotate(-45deg)'        
      })
      setAfter({
        background: 'white',            
        display: 'block',
        height: '2px',
        position: 'relative',
        transition: 'all .2s ease-out',            
        top: '0',
        transform: 'rotate(45deg)'
      })
      setMid({
        background: 'transparent',
        display: 'block',
        height: '2px', 
        position: 'relative',             
        width: '24px', 
      })
    } else {
      setNav({
        width: '100%',
        height: '100%',
        position: 'fixed',
        overflow: 'hidden',              
        backgroundColor: 'rgb(52, 67, 86)',
        color: '#fff',
        maxHeight: '0',
        transition: 'maxHeight .5s ease-out'
      })
      setBefore({
        background: 'white',
        content: '',
        display: 'block',
        height: '2px',
        position: 'relative',
        transition: 'all .2s ease-out',            
        top: '8px'
      })
      setAfter({
        background: 'white',            
        display: 'block',
        height: '2px',
        position: 'relative',
        transition: 'all .2s ease-out',            
        top: '-8px'        
      })
      setMid({
        background: 'white',
        display: 'block',
        height: '2px', 
        position: 'relative',             
        width: '24px', 
      })
    }
  }

  return(
    <header style={{
      position: 'sticky',
      top: '0px',
      backgroundColor: 'rgba(52, 67, 86, 0)',
      backgroundImage: `url(${process.env.PUBLIC_URL}/Kas-Barra.jpg)`,      
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      backgroundPosition: '50% 70%',
      width: '100%',
      fontFamily: 'Roxborough',
      textAlign: 'left',      
    }}>
      <a href="/carta" style={{display: 'inline-block', marginLeft: '0px', padding: '10px'}}>
        <img src={`${process.env.PUBLIC_URL}/Logo-KasBlanco.png`} alt="Kas" style={{width:'15rem'}}/>
      </a>
      <input onChange={(e) => check(e)} type="checkbox" id='ch-tgg' style={{display: 'none'}}/>
      <label htmlFor="ch-tgg" style={hamb}>
          <span style={before}></span>
          <span style={mid}></span>
          <span style={after}></span>
      </label>
      <nav style={navC}>        
        <ul>
          {userRole && userRole.role ? roles() : 
            <div style={{fontSize: '18px', zIndex: '0', textAlign: 'center', fontFamily: 'proxima-nova', fontWeight: 'bold'}}>
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/carta"><span>menú</span></a></li>              
              <li style={listyle}><a style={{color: '#fff', textDecoration: 'none', display: 'block', padding: '30px'}} href="/signup"><span>login</span></a></li>                    
            </div> 
          }                                                           
        </ul>
      </nav>      
    </header>
  )

}

export default Navbar;
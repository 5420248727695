import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

function Usuarios () {  
  const [users, setUsers] = useState([]);  
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/usuarios`, {withCredentials: true})
    .then(response => {
      setUsers(response.data)
    })
    .catch(err => {console.log(err)})
  }, [])

  var change = (id) => {
    var rol = document.getElementById(id).value;    
    axios.put(`${process.env.REACT_APP_API_URL}/usuarios`, {id, role: rol}, {withCredentials: true})
    .then(response => {
      console.log(response.data)
    })
    .catch(err => {console.log(err)})
  }

  return(
    <div>
      <h1>Registro de usuarios</h1>
      <h3>Asignación de roles</h3>
      <table style={{width: '80%', border: '1px solid white', borderCollapse: 'collapse', margin: 'auto', fontSize: '.8rem'}}>
        <thead>
          <tr>
            <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>ID</th>
            <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Usuario</th>
            <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Inicio</th>
            <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Rol</th>
            <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>Cambiar Rol</th>
            <th style={{border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}></th>
          </tr>
        </thead>
        <tbody>
          {users ? users.map(user => {
            var d    = new Date(user.createdAt)
            var begg = d.toUTCString()
            return(
              <tr key={user._id}>
                <th>{user._id}</th>
                <th>{user.username}</th>
                <th>{begg}</th>
                <th>{user.role}</th>
                <th>
                  <select id={user._id}>
                    <option value="ADMIN">ADMIN</option>
                    <option value="COCINA">COCINA</option>
                    <option value="PISO">PISO</option>
                    <option value="BARRA">BARRA</option>
                    <option value="CLIENTE">CLIENTE</option>
                  </select>
                </th>
                <th>
                  <button onClick={() => change(user._id)}>Cambiar</button>
                </th>
              </tr>
            )
          }) : null}
        </tbody>
      </table>      
    </div>
  )

}

export default Usuarios;
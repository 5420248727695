import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { AuthContext } from './auth/Context';

function Inventario () {
  const [botella, setBotella]   = useState([]);
  const [barril, setBarril]     = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [fuertes, setFuertes]   = useState([]);
  const [postres, setPostres]   = useState([]);
  const authContext             = useContext(AuthContext);
  const [onSwitch]              = useState(
    { 
      display: 'inline-block',
      cursor: 'pointer',
      backgroundColor: '#3d6178',
      transition: '.4s',
      borderRadius: '34px',
      width: '70%',
      height: '90%',      
      paddingLeft: '20px'
    }
  );
  const [offSwitch]             = useState(
    { 
      display: 'inline-block',
      cursor: 'pointer',
      backgroundColor: '#ccc',
      transition: '.4s',
      borderRadius: '34px',
      width: '70%',
      height: '90%',
      paddingRight: '20px'      
    }
  );

  const MXN = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/inventario`, {withCredentials: true})
    .then(response => {
      var fac = [];
      var bar = [];
      for(var f = 0; f < response.data.length; f++){
        if(response.data[f].cerveza) {
          for(var b = 0; b < response.data[f].cerveza.length; b++){            
            if(response.data[f].cerveza[b].tipo !== "Barril"){
              response.data[f].cerveza[b].factura = response.data[f]._id
              fac.push(response.data[f].cerveza[b])  
            } else {
              response.data[f].cerveza[b].factura = response.data[f]._id
              bar.push(response.data[f].cerveza[b])
            }            
          }                     
        }        
      }
      setBotella(fac);
      setBarril(bar);
    })
    .catch(err => {console.log(err)})
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/carta`, {withCredentials: true})
    .then(response => {
      var entradas  = [];
      var fuertes   = [];
      var postres   = [];
      for(var f = 0; f < response.data.length; f++){
        if(response.data[f].tiempo) {
          if(response.data[f].tiempo === "entrada"){
            entradas.push(response.data[f])
          } else if(response.data[f].tiempo === "fuerte"){
            fuertes.push(response.data[f])
          } else if(response.data[f].tiempo === "postre"){
            postres.push(response.data[f])
          }
        }        
      }
      setEntradas(entradas);
      setFuertes(fuertes);
      setPostres(postres);
    })
    .catch(err => {console.log(err)})
  }, [])

  var activeToggle = (id, tempo, base) => {    
    var b = base;
    var state = "";
    for(var c = 0; c < b.length; c++){
      if(b[c]._id === id){
        if(b[c].activo){
          b[c].activo = false;
          state = false;
        } else {
          b[c].activo = true;
          state = true
        }
      }
    }
    
    if(tempo === "entradas"){
      setEntradas([...b]);      
    }
    if(tempo === "fuerte"){
      setFuertes([...b]);      
    }
    if(tempo === "postre"){
      setPostres([...b]);      
    }

    axios.put(`${process.env.REACT_APP_API_URL}/platillo`, {id, state}, {withCredentials: true})
    .then(response => {
      console.log(response);
    })
    .catch(err => {console.log(err)})    
  }

  var activeToggleBeer = (id, idBeer, tipo, base) => {    
    var b = base;
    var state = "";
    for(var c = 0; c < b.length; c++){
      if(b[c]._id === idBeer){
        if(b[c].activo){
          b[c].activo = false;
          state = false;
        } else {
          b[c].activo = true;
          state = true
        }
      }
    }
    if(tipo === "barril"){
      setBarril([...b]);      
    }
    if(tipo === "botella"){
      setBotella([...b]);      
    }

    axios.put(`${process.env.REACT_APP_API_URL}/inventario`, {id, idBeer, state}, {withCredentials: true})
    .then(response => {
      console.log(response);
    })
    .catch(err => {console.log(err)})    
  }
  
  return(
    <div style={{marginBottom: '0', fontFamily: 'proxima-nova'}}>                 
          <table style={{width: "100%", paddingLeft: '5%'}}>
            <colgroup>
                <col span="1" style={{width: '100%'}}/>
                <col span="1" style={{width: '40%'}}/>
            </colgroup>
            <thead>
              <tr>
                <th style={{textAlign: 'left'}}><h2 style={{backgroundImage: 'linear-gradient(to right, #e6dfd2, rgba(255,255,255,0), rgba(255,255,255,0))', fontFamily: 'fields'}}>entradas</h2></th>            
                <th></th>
                <th></th>
              </tr>                                      
            </thead>
            <tbody>
              {entradas ? entradas.map(ent => {  
                var auth = authContext.isLoggedIn.loggedInUser;
                if(!auth){
                  auth = "CLIENTE"
                }                
                var disp = auth === "CLIENTE" && ent.activo === false ? "none" : "block";
                  return(
                    <tr style={{display: disp}} key={ent._id}>
                      <td style={{textAlign: 'left', padding: '5px', width: '100%'}}>
                        <div><b>{(ent.nombre).toUpperCase()}</b></div>
                        <div><i>{ent.descripcion}</i></div>
                      </td>
                      <td><b>{MXN.format(ent.precio)}</b></td>
                      {authContext.isLoggedIn.loggedInUser && authContext.isLoggedIn.loggedInUser.role !== "CLIENTE" ? 
                        <td>
                          <label style={{ display: 'inline-block', width: '60px', height: '34px'}}>                              
                            <span onClick={() => activeToggle(ent._id, 'entradas', entradas)} style={ent.activo ? onSwitch : offSwitch}>
                              <span style={{                                                                    
                                display: 'inline-block',
                                height: '26px',                                   
                                width: '26px',
                                marginTop: '2px',
                                backgroundColor: 'white',                                  
                                transition: '.4s',
                                borderRadius: '50%',
                                color: 'white',                                  
                              }}>.</span>
                            </span>
                          </label>                            
                        </td> : null}                
                    </tr>                                             
                  )
                }) : null}                
            </tbody>            
          </table>     
          <table style={{width: "100%", paddingLeft: '5%'}}>
            <colgroup>
                <col span="1" style={{width: '100%'}}/>
                <col span="1" style={{width: '40%'}}/>
              </colgroup>
            <thead>
              <tr>
                <th style={{textAlign: 'left'}}><h2 style={{backgroundImage: 'linear-gradient(to right, #e6dfd2, rgba(255,255,255,0), rgba(255,255,255,0))', fontFamily: 'fields'}}>platos fuertes</h2></th>            
                <th></th>
                <th></th>
              </tr>                                      
            </thead>
            <tbody>
              {fuertes ? fuertes.map(fuerte => {  
                var auth = authContext.isLoggedIn.loggedInUser;
                if(!auth){
                  auth = "CLIENTE"
                }       
                var disp = auth === "CLIENTE" && fuerte.activo === false ? "none" : "block";            
                  return(
                    <tr style={{display: disp}} key={fuerte._id}>
                      <td style={{textAlign: 'left', padding: '5px', width: '100%'}}>
                        <div><b>{(fuerte.nombre).toUpperCase()}</b></div>
                        <div><i>{fuerte.descripcion}</i></div>
                      </td>
                      <td><b>{MXN.format(fuerte.precio)}</b></td>
                      {authContext.isLoggedIn.loggedInUser && authContext.isLoggedIn.loggedInUser.role !== "CLIENTE" ? 
                        <td>
                          <label style={{ display: 'inline-block', width: '60px', height: '34px'}}>                              
                            <span onClick={() => activeToggle(fuerte._id, 'fuerte', fuertes)} style={fuerte.activo ? onSwitch : offSwitch}>
                              <span style={{                                                                    
                                display: 'inline-block',
                                height: '26px',                                   
                                width: '26px',
                                marginTop: '2px',
                                backgroundColor: 'white',                                  
                                transition: '.4s',
                                borderRadius: '50%',
                                color: 'white',                                  
                              }}>.</span>
                            </span>
                          </label>                            
                        </td> : null}
                    </tr>                                              
                  )
                }) : null}                
            </tbody>            
          </table>   
            <table style={{width: "100%", paddingLeft: '5%'}}>
              <colgroup>
                <col span="1" style={{width: '100%'}}/>
                <col span="1" style={{width: '40%'}}/>
              </colgroup>
              <thead>
                <tr>
                  <th style={{textAlign: 'left'}}><h2 style={{backgroundImage: 'linear-gradient(to right, #e6dfd2, rgba(255,255,255,0), rgba(255,255,255,0))', fontFamily: 'fields'}}>postres</h2></th>            
                  <th></th>
                  <th></th>
                </tr>                                      
              </thead>
              <tbody>
                {postres ? postres.map(postre => {
                  var auth = authContext.isLoggedIn.loggedInUser;
                  if(!auth){
                    auth = "CLIENTE"
                  }       
                  var disp = auth === "CLIENTE" && postre.activo === false ? "none" : "block";              
                    return(
                      <tr style={{display: disp}} key={postre._id}>
                        <td style={{textAlign: 'left', padding: '5px', width: '100%'}}>
                          <div><b>{(postre.nombre).toUpperCase()}</b></div>
                          <div><i>{postre.descripcion}</i></div>
                        </td>
                        <td><b>{MXN.format(postre.precio)}</b></td>  
                        {authContext.isLoggedIn.loggedInUser && authContext.isLoggedIn.loggedInUser.role !== "CLIENTE" ? 
                          <td>
                            <label style={{ display: 'inline-block', width: '60px', height: '34px'}}>                              
                              <span onClick={() => activeToggle(postre._id, 'postre', postres)} style={postre.activo ? onSwitch : offSwitch}>
                                <span style={{                                                                    
                                  display: 'inline-block',
                                  height: '26px',                                   
                                  width: '26px',
                                  marginTop: '2px',
                                  backgroundColor: 'white',                                  
                                  transition: '.4s',
                                  borderRadius: '50%',
                                  color: 'white',                                  
                                }}>.</span>
                              </span>
                            </label>                            
                          </td> : null}
                      </tr>                                             
                    )
                  }) : null}                
              </tbody>            
            </table>                    
            <table style={{width: "100%", paddingLeft: '5%'}}>
              <colgroup>
                <col span="1" style={{width: '100%'}}/>
                <col span="1" style={{width: '100%'}}/>
                <col span="1" style={{width: '100%'}}/>
                <col span="1" style={{width: '100%'}}/>
                <col span="1" style={{width: '100%'}}/>
              </colgroup>
              <thead>
                <tr>
                  <th style={{textAlign: 'left'}}><h2 style={{backgroundImage: 'linear-gradient(to right, #e6dfd2, rgba(255,255,255,0), rgba(255,255,255,0))', fontFamily: 'fields'}}>cerveza de barril</h2></th>
                  <th>250ML</th>
                  <th>330ML</th>
                  <th>500ML</th>
                  <th></th>
                  <th></th>
                </tr>                                      
              </thead>
              <tbody>
                {barril ? barril.map(bar => {                  
                    var pxml = (bar.precio/(bar.cantidad - 5000));
                    var auth = authContext.isLoggedIn.loggedInUser;
                    if(!auth){
                      auth = "CLIENTE"
                    }       
                    var disp = auth === "CLIENTE" && bar.activo === false ? "none" : "table-row";
                    return(
                      <tr style={{display: disp}} key={bar._id}>
                        <td style={{textAlign: 'left', padding: '5px'}}><b>{bar.cervecera} | {bar.nombre}</b> <i>{bar.estilo}</i></td>
                        <td style={{width: '10%'}}><b>{MXN.format(Math.ceil(pxml*250))}</b></td>
                        <td style={{width: '10%'}}><b>{MXN.format(Math.ceil(pxml*330))}</b></td>
                        <td><b>{MXN.format(Math.ceil(pxml*500))}</b></td>
                        <td></td>                        
                        {authContext.isLoggedIn.loggedInUser && authContext.isLoggedIn.loggedInUser.role !== "CLIENTE" ? 
                          <td>
                            <label style={{ display: 'inline-block', width: '60px', height: '34px'}}>                              
                              <span onClick={() => activeToggleBeer(bar.factura, bar._id, 'barril', barril)} style={bar.activo ? onSwitch : offSwitch}>
                                <span style={{                                                                    
                                  display: 'inline-block',
                                  height: '26px',                                   
                                  width: '26px',
                                  marginTop: '2px',
                                  backgroundColor: 'white',                                  
                                  transition: '.4s',
                                  borderRadius: '50%',
                                  color: 'white',                                  
                                }}>.</span>
                              </span>
                            </label>                            
                          </td> : null}  
                      </tr>                                             
                    )
                  }) : null}                
              </tbody>            
            </table> 
            <table style={{width: "100%", paddingLeft: '5%', paddingBottom: '5%'}}>
              <colgroup>
                <col span="1" style={{width: '100%'}}/>
                <col span="1" style={{width: '40%'}}/>
              </colgroup>
              <thead>
                <tr>
                  <th style={{textAlign: 'left'}}><h2 style={{backgroundImage: 'linear-gradient(to right, #e6dfd2, rgba(255,255,255,0), rgba(255,255,255,0))', fontFamily: 'fields'}}>cerveza de botella</h2></th>            
                  <th></th>
                  <th></th>
                </tr>                                      
              </thead>
              <tbody>
                {botella ? botella.map(bot => {     
                  var auth = authContext.isLoggedIn.loggedInUser;
                  if(!auth){
                    auth = "CLIENTE"
                  }       
                  var disp = auth === "CLIENTE" && bot.activo === false ? "none" : "table-row";         
                    return(
                      <tr style={{display: disp}} key={bot._id}>
                        <td style={{textAlign: 'left', padding: '0px'}}>
                          <div><b>{bot.cervecera} | {bot.nombre}</b> <i>{bot.estilo}</i></div>
                          <div><i>{bot.descripcion}</i></div>
                        </td>
                        <td><b>{MXN.format(bot.precio)}</b></td>  
                        {authContext.isLoggedIn.loggedInUser && authContext.isLoggedIn.loggedInUser.role !== "CLIENTE" ? 
                          <td>
                            <label style={{ display: 'inline-block', width: '60px', height: '34px'}}>                              
                              <span onClick={() => activeToggleBeer(bot.factura, bot._id, 'botella', botella)} style={bot.activo ? onSwitch : offSwitch}>
                                <span style={{                                                                    
                                  display: 'inline-block',
                                  height: '26px',                                   
                                  width: '26px',
                                  marginTop: '2px',
                                  backgroundColor: 'white',                                  
                                  transition: '.4s',
                                  borderRadius: '50%',
                                  color: 'white',                                  
                                }}>.</span>
                              </span>
                            </label>                            
                          </td> : null}                
                      </tr>                                             
                    )
                  }) : null}                
              </tbody>            
            </table>          
    </div>
  )

}

export default Inventario;
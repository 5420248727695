import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Cocina () {
  const [plat, setPlat]       = useState([]);
  const [ingr, setIngr]       = useState([]);  
  const [usoIng, setUsoI]     = useState([]);  
  const [plati]               = useState('block');     
  
  const MXN = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});
  const Num = new Intl.NumberFormat();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/platillos`, {withCredentials: true})
    .then(response => {
      setPlat(response.data);     
    })
    .catch(err => {console.log(err)})
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/ingredientes`, {withCredentials: true})
    .then(response => {
      setIngr(response.data);        
    })
    .catch(err => {console.log(err)})
  }, [])

  var addIng = () => {
    var id  = document.getElementById("selIngr").value;
    var uso = document.getElementById("uso").value;
    var res = ingr.filter(i => i._id === id);
    var u = [...usoIng];
    u.push({
      insumo: res[0].nombre,
      gramajeUso: uso,
      costoUso: res[0].costo/res[0].gramaje * uso,
      marca: res[0].marca,
    })
    setUsoI(u);
  }

  var editButton = (id, ins) => {
    var ids = [
      'CancelB-' + id,  
      'ModifyB-' + id,
      'DeleteB-' + id,
      'Nombre-' + id,
      'Costo-' + id,
      'Precio-' + id,
      'Tiempo-' + id,
      'Descripcion-' + id,      
    ]

    for(var i = 0; i < ins; i++){
      ids.push('Insumo-' + id + '-' + i)
    }

    var ed = 'EditB-' + id;
    
    for(var e = 0; e < ids.length; e++){
      document.getElementById(ids[e]).style.display = 'block';
    }     
    document.getElementById(ed).style.display = 'none'   
  }

  var cancelButton = (id, ins) => {
    var ids = [
      'CancelB-' + id,  
      'ModifyB-' + id,
      'DeleteB-' + id,
      'Nombre-' + id,
      'Costo-' + id,
      'Precio-' + id,
      'Tiempo-' + id,
      'Descripcion-' + id,
    ]

    for(var i = 0; i < ins; i++){
      ids.push('Insumo-' + id + '-' + i)
    }

    var ed = 'EditB-' + id;
    
    for(var e = 0; e < ids.length; e++){
      document.getElementById(ids[e]).style.display = 'none';
    }      
    document.getElementById(ed).style.display = 'block'  
  }

  var altaPlato = () => {
    var imagen = "";
    var nombre = document.getElementById("nombrePlato").value;
    var descripcion = document.getElementById("descPlato").value;
    var ingredientes = usoIng;
    var alergenos = [];
    var precio = document.getElementById("precio").value;
    var costo = usoIng.reduce((sum,us) => sum + us.costoUso, 0);
    var tiempo = document.getElementById("tiempo").value;
     
    if(nombre === "" || descripcion === "" || ingredientes.length === 0 || precio === "" || descripcion === "" || tiempo === ""){
      alert("Completa los campos de 'Alta de platillo'");
      return
    }

    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE ALTA? Nombre: ${nombre}; Precio: ${MXN.format(precio)}; Costo: ${MXN.format(costo)}; Tiempo: ${tiempo}`
    )){
      axios.post(`${process.env.REACT_APP_API_URL}/platillo`, {imagen, nombre, descripcion, ingredientes, alergenos, precio, costo, tiempo}, {withCredentials: true})
      .then(response => {
          
        document.getElementById("nombrePlato").value = "";
        document.getElementById("descPlato").value = "";          
        document.getElementById("precio").value = "";        
        setUsoI([]);        
      })
      .catch(err => console.log(err))
      return
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  var deletePlato = (id, nombre) => {       
     
    if(window.confirm(
      `¿SEGURO QUE QUIERE DAR DE BAJA? ID: ${id}; Nombre: ${nombre};`
    )){
      axios.post(`${process.env.REACT_APP_API_URL}/platilloDelete`, {id}, {withCredentials: true})
      .then(response => {
        console.log(response);
        window.location.reload()
      })
      .catch(err => console.log(err))
      return
    } else{
      console.log("Operación cancelada")
      return
    }
  }

  return(
    <div style={{marginBottom: '0', fontFamily: 'Roxborough'}}>
      <div style={{margin: '20px'}}>
        <button style={{width: '100%',backgroundColor:'rgb(52,67,86)', fontWeight: 'bold', fontSize: '25px', padding: '5px', border: 'none', color:'white'}}>Platillos</button>        
      </div>
      <div style={{display: plati}}>
        <div style={{textAlign: 'left', padding: '10px'}}>
          <h3>Alta de platillo</h3>
          <p>Alta de platillos nuevos a la carta</p>
          <div style={{overflowX: 'auto'}}>
            <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
              <thead>
                <tr>
                  <th>Nombre:</th>                                   
                  <th>Tiempo:</th>
                  <th>Costo:</th>                                                    
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" id='nombrePlato' placeholder='Nombre'/></td>                  
                  <td>
                    <select id="tiempo">
                      <option value="entrada">Entrada</option>
                      <option value="fuerte">Fuerte</option>
                      <option value="postre">Postre</option>
                    </select>
                  </td>
                  <td>{usoIng.reduce((sum,us) => sum + us.costoUso, 0)}</td>                  
                </tr>
              </tbody>
            </table>           
          </div>
          <div>
            <p><strong>Precio:</strong></p>
            <input type="number" id='precio' placeholder='Precio'/>
            <p><strong>Descripción:</strong></p>
            <textarea style={{width: '100%'}} type="text" id='descPlato' placeholder='Descripción'/>
          </div>
          <div>
            <p><strong>Ingrediente nuevo</strong></p>            
            <select id="selIngr">
              {ingr ? ingr.map((i, idx) => {
                return(
                  <option key={idx} value={i._id}>{i.nombre} - {i.marca}</option>
                )
              }) : null}
            </select>
            <input id='uso' placeholder='Uso (gr/ml)' type="number" />
            <div style={{textAlign: 'right', margin: '50px'}}>
              <button style={{width: '100%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}} onClick={() => addIng()}>Agregar ingrediente</button>
            </div>              
            <p>Lista de ingredientes para el platillo nuevo:</p>
            <div>
              {usoIng ? usoIng.map((u, od) =>{
                return(
                  <li key={od}>{u.insumo} - {u.gramajeUso}</li>
                )
              }): null}
            </div> 
          </div>
          <div style={{textAlign: 'right', margin: '50px'}}>
            <button style={{width: '100%', height: '50px', margin: 'auto', border: 'solid white', borderRadius: '10px', color: 'white', fontWeight: 'bold', backgroundColor: 'rgb(52, 67, 86)'}} onClick={() => altaPlato()}>Alta platillo</button>         
          </div>            
        </div>
        <hr />
        <div>
        {plat ? plat.map((p, idx) => {
          return(
            <div key={idx} style={{border: "2px solid black", borderRadius: '5px', margin: '10px', textAlign: 'left', padding: '2rem'}}>
              <div style={{display: 'grid', gridTemplateColumns: 'auto auto auto'}}>                              
                <button id={`CancelB-${idx}`} style={{display: 'none'}} onClick={() => cancelButton(idx, p.ingredientes.length)}>Cancelar</button>
                <button id={`ModifyB-${idx}`} style={{display: 'none'}}>Modificar</button>
                <button id={`DeleteB-${idx}`} style={{display: 'none', backgroundColor: 'red'}} onClick={() => deletePlato(p._id, p.nombre)}>Eliminar</button>
                <button id={`EditB-${idx}`} onClick={() => editButton(idx, p.ingredientes.length)}>Editar</button>
              </div>
              <h3>{p.nombre}</h3>              
              <input id={`Nombre-${idx}`} style={{display: 'none'}} type="text" placeholder={`${p.nombre}`}/>
              <p><strong>Costo: {MXN.format(p.costo)}</strong></p>
              <input id={`Costo-${idx}`} style={{display: 'none'}} type="text" placeholder={`${p.costo}`}/>
              <p><strong>Precio: {MXN.format(p.precio)}</strong></p>
              <input id={`Precio-${idx}`} style={{display: 'none'}} type="text" placeholder={`${p.precio}`}/>
              <p><strong>Tiempo:</strong> {p.tiempo}</p>
              <input id={`Tiempo-${idx}`} style={{display: 'none'}} type="text" placeholder={`${p.tiempo}`}/>
              <p>{p.descripcion}</p>
              <textarea id={`Descripcion-${idx}`} style={{width: '100%', display: 'none'}} type="text" placeholder={`${p.descripcion}`}/>  
              <p><strong>Insumos:</strong></p>
              <div style={{overflowX: 'auto'}}>
                <table style={{width: '100%'}}>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Uso (gramaje)</th>
                      <th>Costo</th>                    
                    </tr>
                  </thead>
                  <tbody>                
                    {p.ingredientes ? p.ingredientes.map((c, iidx) => {
                      var ing = ingr.filter(i => i.nombre === c.insumo && i.cantidad > 0)
                      return(                      
                        <tr key={iidx}>
                          <td>{c.insumo}</td>
                          <td>
                            {Num.format(c.gramajeUso)}
                            <input id={`Insumo-${idx}-${iidx}`} style={{display: 'none'}} type="text" placeholder={`${c.gramajeUso}`}/>
                          </td>                                                  
                          <td>{ing.length > 0 ? MXN.format((ing[0].costo/ing[0].gramaje) * c.gramajeUso) : <span style={{color: 'red'}}>Sin insumo</span>}</td> 
                        </tr>
                      )
                    }): null}                 
                  </tbody>
                </table>
              </div>
            </div>
          )}) : null}  
        </div>            
      </div>
    </div>
  )
}

export default Cocina;
import axios from 'axios';

let service = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`, 
  withCredentials: true 
});

export const Signup = (user, username, password) => {
    return service.post('/signup', {user, username, password})
    .then(response => response.data);
  }

export const LoggedIn = () => {
  return service.get('/loggedin')
  .then(response => response.data)
}

export const Login = (username, password) => {
  return service.post('/login', {username, password})
  .then(response => response.data)
}

export const Logout = () => {
  return service.post('/logout', {})
  .then(response => response.data)
}
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { useInput } from './useInput';
import { AuthContext } from './auth/Context';

function Ventas () {  
  const [tickets, setTickets] = useState([]);
  const [poliza, setPolizas]  = useState([]);   
  const [filtro, setFiltro]   = useState([]);
  const [timeSeries, setTime] = useState([]);
  const [acum, setAcum]       = useState(0);
  const [ventas, setVentas]   = useState([0,0,0]);
  const [costoV, setCostoV]   = useState([0,0,0]);
  const [gastosF, setGastosF] = useState([0,0,0]);
  const [prodF, setProdF]     = useState([0,0,0]);
  const [gastosV, setGastosV] = useState([0,0,0]);
  const [gastosA, setGastosA] = useState([0,0,0]);
  const [otrosI, setOtrosI]   = useState([0,0,0]);
  const [otrosG, setOtrosG]   = useState([0,0,0]);
  const [isr, setISR]         = useState([0,0,0]);  
  
  const [border]              = useState({border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'})
  const [borderNT]            = useState({border: '1px solid white', borderCollapse: 'collapse', textAlign: 'right', fontWeight: '900', backgroundColor: 'rgb(86,144,149)'})
  const [borderNS]            = useState({border: '1px solid white', borderCollapse: 'collapse', textAlign: 'right', fontWeight: '100', backgroundColor: 'rgb(197,221,226)'})
  const [borderT]             = useState({border: '1px solid white', borderCollapse: 'collapse', textAlign: 'left', paddingLeft: '5%', fontWeight: '900', backgroundColor: 'rgb(86,144,149)'})
  const [borderS]             = useState({border: '1px solid white', borderCollapse: 'collapse', textAlign: 'left', paddingLeft: '5%', fontWeight: '100', backgroundColor: 'rgb(197,221,226)'})

  const MXN         = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});
  const authContext = useContext(AuthContext);
  
  const {value: nomYear, bind: bindYear, reset: resetYear} = useInput('');  
  const {value: nomMes, bind: bindMes, reset: resetMes} = useInput('');
  console.log(poliza)
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/tickets`, {withCredentials: true})
    .then(response => {      
      var data = response.data;
      var thisYear = new Date().getFullYear();
      var ordered = [];
      var years = [];
      for(var y = 2022; y <= thisYear; y++){   
        years.push(y);     
        let dat = [];
        let temp = {
          year: y, 
          tickets: dat,
          months: [
            {
              mes: 'enero',
              venta: 0,
              tickets: []
            },
            {
              mes: 'febrero',
              venta: 0,
              tickets: []
            },
            {
              mes: 'marzo',
              venta: 0,
              tickets: []
            },
            {
              mes: 'abril',
              venta: 0,
              tickets: []
            },
            {
              mes: 'mayo',
              venta: 0,
              tickets: []
            },
            {
              mes: 'junio',
              venta: 0,
              tickets: []
            },
            {
              mes: 'julio',
              venta: 0,
              tickets: []
            },
            {
              mes: 'agosto',
              venta: 0,
              tickets: []
            },
            {
              mes: 'septiembre',
              venta: 0,
              tickets: []
            },
            {
              mes: 'octubre',
              venta: 0,
              tickets: []
            },
            {
              mes: 'noviembre',
              venta: 0,
              tickets: []
            },
            {
              mes: 'diciembre',
              venta: 0,
              tickets: []
            }
          ]
        };
        for(var tic = 0; tic < data.length; tic++){
          let d = new Date(data[tic].updatedAt);
          let year = d.getFullYear();
          let month = d.getMonth();
          if(year === y){            
            dat.push(data[tic]);
            temp.months[month].tickets.push(data[tic]) 
          }
          let sales = temp.tickets.length > 0 ? temp.tickets.reduce((sal, t) => sal + t.total, 0) : 0;
          temp.venta = sales;
        }
        for(var t = 0; t < 12; t++){
          let v = temp.months[t].tickets.length > 0 ? temp.months[t].tickets.reduce((total, n) => total + n.total, 0) : 0;
          temp.months[t].venta = v;
        }
        ordered.push(temp);
      }      
      setTickets(response.data);
      setFiltro(ordered);
      setTime(years);
    })
    .catch(err => {console.log(err)})
  }, [])

  useEffect(() =>{    
    var months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]

    var ventasAcc    = poliza.filter(f => f.cuentaCargo === "Ventas")
    var ventaAcc$    = ventasAcc.reduce((acc, curr) => acc + curr.monto, 0)
    var costoAcc     = poliza.filter(f => f.cuentaAbono === "Costo de Ventas")
    var costoAcc$    = costoAcc.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoFAcc    = poliza.filter(f => f.cuentaAbono === "Gastos Financieros")
    var gastoFAcc$   = gastoFAcc.reduce((acc, curr) => acc + curr.monto, 0)
    var ingreFAcc    = poliza.filter(f => f.cuentaCargo === "Productos Financieros")
    var ingreFAcc$   = ingreFAcc.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoVAcc    = poliza.filter(f => f.cuentaAbono === "Gastos de Venta")
    var gastoVAcc$   = gastoVAcc.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoAdmAcc  = poliza.filter(f => f.cuentaAbono === "Gasto de Administracion")
    var gastoAdmAcc$ = gastoAdmAcc.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoOtrAcc  = poliza.filter(f => f.cuentaAbono === "Otros Gastos")
    var gastoOtAcc$  = gastoOtrAcc.reduce((acc, curr) => acc + curr.monto, 0)
    var ingreOtrAcc  = poliza.filter(f => f.cuentaCargo === "Otros Ingresos")
    var ingreOtAcc$  = ingreOtrAcc.reduce((acc, curr) => acc + curr.monto, 0)
    var ISRAcc       = poliza.filter(f => f.cuentaAbono === "ISR")
    var ISRAcc$      = ISRAcc.reduce((acc, curr) => acc + curr.monto, 0)

    var UtPerAcc     = ventaAcc$ - costoAcc$ - gastoFAcc$ + ingreFAcc$ - gastoVAcc$ - gastoAdmAcc$ - gastoOtAcc$ + ingreOtAcc$ - ISRAcc$;
    setAcum(UtPerAcc);

    var thisMonth = poliza.filter(f => (new Date(f.fecha).getMonth() + " " + new Date(f.fecha).getFullYear()) === (months.indexOf(nomMes) + " " + nomYear));        
    var ventas    = thisMonth.filter(f => f.cuentaCargo === "Ventas")
    var venta$    = ventas.reduce((acc, curr) => acc + curr.monto, 0)
    var costo     = thisMonth.filter(f => f.cuentaAbono === "Costo de Ventas")
    var costo$    = costo.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoF    = thisMonth.filter(f => f.cuentaAbono === "Gastos Financieros")
    var gastoF$   = gastoF.reduce((acc, curr) => acc + curr.monto, 0)
    var ingreF    = thisMonth.filter(f => f.cuentaCargo === "Productos Financieros")
    var ingreF$   = ingreF.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoV    = thisMonth.filter(f => f.cuentaAbono === "Gastos de Venta")
    var gastoV$   = gastoV.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoAdm  = thisMonth.filter(f => f.cuentaAbono === "Gasto de Administracion")
    var gastoAdm$ = gastoAdm.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoOtr  = thisMonth.filter(f => f.cuentaAbono === "Otros Gastos")
    var gastoOt$  = gastoOtr.reduce((acc, curr) => acc + curr.monto, 0)
    var ingreOtr  = thisMonth.filter(f => f.cuentaCargo === "Otros Ingresos")
    var ingreOt$  = ingreOtr.reduce((acc, curr) => acc + curr.monto, 0)
    var ISR       = thisMonth.filter(f => f.cuentaAbono === "ISR")
    var ISR$      = ISR.reduce((acc, curr) => acc + curr.monto, 0)
    

    var lastMonth = poliza.filter(f => (new Date(f.fecha).getMonth() + " " + new Date(f.fecha).getFullYear()) === (months.indexOf(nomMes) + " " + (Number(nomYear) - 1)));
    var ventasLM  = lastMonth.filter(f => f.cuentaCargo === "Ventas")
    var ventaLM$  = ventasLM.reduce((acc, curr) => acc + curr.monto, 0)
    var costoLM   = lastMonth.filter(f => f.cuentaAbono === "Costo de Ventas")
    var costoLM$  = costoLM.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoFLM  = lastMonth.filter(f => f.cuentaAbono === "Gastos Financieros")
    var gastoFLM$ = gastoFLM.reduce((acc, curr) => acc + curr.monto, 0)
    var ingreFLM  = lastMonth.filter(f => f.cuentaCargo === "Productos Financieros")
    var ingreFLM$ = ingreFLM.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoVLM  = lastMonth.filter(f => f.cuentaAbono === "Gastos de Venta")
    var gastoVLM$ = gastoVLM.reduce((acc, curr) => acc + curr.monto, 0)
    var gastAdmLM = lastMonth.filter(f => f.cuentaAbono === "Gasto de Administracion")
    var gastAdLM$ = gastAdmLM.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoOtLM = lastMonth.filter(f => f.cuentaAbono === "Otros Gastos")
    var gstOtLM$  = gastoOtLM.reduce((acc, curr) => acc + curr.monto, 0)
    var ingOtrLM  = lastMonth.filter(f => f.cuentaCargo === "Otros Ingresos")
    var ingrOtLM$ = ingOtrLM.reduce((acc, curr) => acc + curr.monto, 0)
    var ISRLM     = lastMonth.filter(f => f.cuentaAbono === "ISR")
    var ISRLM$    = ISRLM.reduce((acc, curr) => acc + curr.monto, 0)

    var monthBefo = poliza.filter(f => (new Date(f.fecha).getMonth() + " " + new Date(f.fecha).getFullYear()) === ((months.indexOf(nomMes) === 0 ? 11 : months.indexOf(nomMes) - 1) + " " + (Number(nomYear))));
    var ventasMB  = monthBefo.filter(f => f.cuentaCargo === "Ventas")
    var ventaMB$  = ventasMB.reduce((acc, curr) => acc + curr.monto, 0)
    var costoMB   = monthBefo.filter(f => f.cuentaAbono === "Costo de Ventas")
    var costoMB$  = costoMB.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoFMB  = monthBefo.filter(f => f.cuentaAbono === "Gastos Financieros")
    var gastoFMB$ = gastoFMB.reduce((acc, curr) => acc + curr.monto, 0)
    var ingreFMB  = monthBefo.filter(f => f.cuentaCargo === "Productos Financieros")
    var ingreFMB$ = ingreFMB.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoVMB  = monthBefo.filter(f => f.cuentaAbono === "Gastos de Venta")
    var gastoVMB$ = gastoVMB.reduce((acc, curr) => acc + curr.monto, 0)
    var gastAdmMB = monthBefo.filter(f => f.cuentaAbono === "Gasto de Administracion")
    var gastAdMB$ = gastAdmMB.reduce((acc, curr) => acc + curr.monto, 0)
    var gastoOtMB = monthBefo.filter(f => f.cuentaAbono === "Otros Gastos")
    var gstOtMB$  = gastoOtMB.reduce((acc, curr) => acc + curr.monto, 0)
    var ingOtrMB  = monthBefo.filter(f => f.cuentaCargo === "Otros Ingresos")
    var ingrOtMB$ = ingOtrMB.reduce((acc, curr) => acc + curr.monto, 0)
    var ISRMB     = monthBefo.filter(f => f.cuentaAbono === "ISR")
    var ISRMB$    = ISRMB.reduce((acc, curr) => acc + curr.monto, 0)

    setVentas([venta$, ventaLM$, ventaMB$]);
    setCostoV([costo$, costoLM$, costoMB$]);
    setGastosF([gastoF$, gastoFLM$, gastoFMB$]);
    setProdF([ingreF$, ingreFLM$, ingreFMB$]);
    setGastosV([gastoV$, gastoVLM$, gastoVMB$]);
    setGastosA([gastoAdm$, gastAdLM$, gastAdMB$]);
    setOtrosG([gastoOt$, gstOtLM$, gstOtMB$]);
    setOtrosI([ingreOt$, ingrOtLM$, ingrOtMB$]);
    setISR([ISR$, ISRLM$, ISRMB$]);    
  }, [nomMes, nomYear, poliza])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/poliza`, {withCredentials: true})
    .then(response => {
      setPolizas(response.data);          
    })
    .catch(err => {console.log(err)})
  }, [])  
  
  return(
    <div style={{marginBottom: '0', fontFamily: 'Roxborough'}}>        
      <div>
        <div style={{margin: 'auto'}}>
          <table style={{width: '100%'}}>
            <thead>
              <tr>
                <th></th>
                <th>Mes</th>
                <th>Año</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{width: '20%'}}>Periodo</td>
                <td>
                  <select style={{
                    padding: '0px',
                    fontSize: '15px',
                    textAlign: 'center',
                    width: '8rem'
                  }} {...bindMes}>                    
                    <option>enero</option>
                    <option>febrero</option>
                    <option>marzo</option>
                    <option>abril</option>
                    <option>mayo</option>
                    <option>junio</option>
                    <option>julio</option>
                    <option>agosto</option>
                    <option>septeimbre</option>
                    <option>octubre</option>
                    <option>noviembre</option>
                    <option>diciembre</option>
                  </select> 
                </td>
                <td>
                  <select style={{
                    padding: '0px',
                    fontSize: '15px',
                    textAlign: 'center',
                    width: '8rem'
                  }}  {...bindYear}>
                    {timeSeries ? timeSeries.map(t => {            
                      return(
                        <option key={t} value={t}>{t}</option>
                      )
                    }) : null}
                  </select>  
                </td>
              </tr>
            </tbody>
          </table>                 
        </div>        
        <h3>Cervecería Kas, S.A.S. de C.V.</h3>
        <h4 style={{fontWeight: '100'}}>Estado de resultados del 1 al cierre de {nomMes} de {nomYear}</h4>
        <table style={{width: '80%', border: '1px solid white', borderCollapse: 'collapse', margin: 'auto', fontSize: '.8rem'}}>
          <thead>
            <tr>
              <th rowSpan={2} style={{width: '50%', border: '1px solid white', borderCollapse: 'collapse', backgroundColor: 'rgb(52,67,86)', color: 'white'}}>CONCEPTOS</th>
              <th colSpan={2} style={border}>MES ACTUAL</th>
              <th colSpan={2} style={border}>MES ANTERIOR</th>
              <th colSpan={2} style={border}>MES AÑO ANTERIOR</th>
            </tr>
            <tr>              
              <th style={border}>IMPORTE</th>
              <th style={border}>%</th>
              <th style={border}>IMPORTE</th>
              <th style={border}>%</th>
              <th style={border}>IMPORTE</th>
              <th style={border}>%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={borderT} colSpan={1}>VENTAS NETAS</td>             
              <td style={borderNT}  colSpan={1}>{MXN.format(ventas[0])}</td>
              <td style={borderNT}  colSpan={1}>100</td>
              <td style={borderNT}  colSpan={1}>{MXN.format(ventas[2])}</td>
              <td style={borderNT} colSpan={1}>100</td>
              <td style={borderNT}  colSpan={1}>{MXN.format(ventas[1])}</td>
              <td style={borderNT} colSpan={1}>100</td>
            </tr>
            <tr>              
              <td style={borderS}>COSTO DE VENTAS</td>              
              <td style={borderNS}>{MXN.format(costoV[0])}</td>
              <td style={borderNS}>{isNaN((costoV[0]/ventas[0]).toFixed(2)) ? 0 : (costoV[0]/ventas[0]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(costoV[2])}</td>
              <td style={borderNS}>{isNaN((costoV[2]/ventas[2]).toFixed(2)) ? 0 : (costoV[2]/ventas[2]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(costoV[1])}</td>
              <td style={borderNS}>{isNaN((costoV[1]/ventas[1]).toFixed(2)) ? 0 : (costoV[1]/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>              
              <td style={borderT}>UTILIDAD (PÉRDIDA) BRUTA</td>              
              <td style={borderNT}>{MXN.format(ventas[0] - costoV[0])}</td>
              <td style={borderNT}>{isNaN(((ventas[0] - costoV[0])/ventas[0]).toFixed(2)) ? 0 : ((ventas[0] - costoV[0])/ventas[0]).toFixed(2)}</td>
              <td style={borderNT}>{MXN.format(ventas[2] - costoV[2])}</td>
              <td style={borderNT}>{isNaN(((ventas[2] - costoV[2])/ventas[2]).toFixed(2)) ? 0 : ((ventas[2] - costoV[2])/ventas[2]).toFixed(2)}</td>
              <td style={borderNT}>{MXN.format(ventas[1] - costoV[1])}</td>
              <td style={borderNT}>{isNaN(((ventas[1] - costoV[1])/ventas[1]).toFixed(2)) ? 0 : ((ventas[1] - costoV[1])/ventas[1]).toFixed(2)}</td>
            </tr>            
            <tr>
              <td style={borderS}>GASTOS Y PRODUCTOS FINANCIEROS</td>              
              <td style={borderNS}>{MXN.format(gastosF[0])}</td>
              <td style={borderNS}>{isNaN((gastosF[0]/ventas[0]).toFixed(2)) ? 0 : (gastosF[0]/ventas[0]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(gastosF[2])}</td>
              <td style={borderNS}>{isNaN((gastosF[2]/ventas[2]).toFixed(2)) ? 0 : (gastosF[2]/ventas[2]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(gastosF[1])}</td>
              <td style={borderNS}>{isNaN((gastosF[1]/ventas[1]).toFixed(2)) ? 0 : (gastosF[1]/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>              
              <td style={borderS}>PRODUCTOS FINANCIEROS</td>               
              <td style={borderNS}>{MXN.format(prodF[0])}</td>
              <td style={borderNS}>{isNaN((prodF[0]/ventas[0]).toFixed(2)) ? 0 : (prodF[0]/ventas[0]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(prodF[2])}</td>
              <td style={borderNS}>{isNaN((prodF[2]/ventas[2]).toFixed(2)) ? 0 : (prodF[2]/ventas[2]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(prodF[1])}</td>
              <td style={borderNS}>{isNaN((prodF[1]/ventas[1]).toFixed(2)) ? 0 : (prodF[1]/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>              
              <td style={borderS}>GASTOS DE VENTA</td>              
              <td style={borderNS}>{MXN.format(gastosV[0])}</td>
              <td style={borderNS}>{isNaN((gastosV[0]/ventas[0]).toFixed(2)) ? 0 : (gastosV[0]/ventas[0]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(gastosV[2])}</td>
              <td style={borderNS}>{isNaN((gastosV[2]/ventas[2]).toFixed(2)) ? 0 : (gastosV[2]/ventas[2]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(gastosV[1])}</td>
              <td style={borderNS}>{isNaN((gastosV[1]/ventas[1]).toFixed(2)) ? 0 : (gastosV[1]/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>              
              <td style={borderS}>GASTOS DE ADMINISTRACIÓN</td>                           
              <td style={borderNS}>{MXN.format(gastosA[0])}</td>
              <td style={borderNS}>{isNaN((gastosA[0]/ventas[0]).toFixed(2)) ? 0 : (gastosA[0]/ventas[0]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(gastosA[2])}</td>
              <td style={borderNS}>{isNaN((gastosA[2]/ventas[2]).toFixed(2)) ? 0 : (gastosA[2]/ventas[2]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(gastosA[1])}</td>
              <td style={borderNS}>{isNaN((gastosA[1]/ventas[1]).toFixed(2)) ? 0 : (gastosA[1]/ventas[1]).toFixed(2)}</td>
            </tr>            
            <tr>                    
              <td style={borderT}>UTILIDAD (PÉRDIDA) DE OPERACIÓN</td>              
              <td style={borderNT}>{MXN.format(ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0])}</td>
              <td style={borderNT}>{isNaN(((ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0])/ventas[0]).toFixed(2)) ? 0 : ((ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0])/ventas[0]).toFixed(2)}</td>
              <td style={borderNT}>{MXN.format(ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2])}</td>
              <td style={borderNT}>{isNaN(((ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2])/ventas[2]).toFixed(2)) ? 0 : ((ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2])/ventas[2]).toFixed(2)}</td>
              <td style={borderNT}>{MXN.format(ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1])}</td>
              <td style={borderNT}>{isNaN(((ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1])/ventas[1]).toFixed(2)) ? 0 : ((ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1])/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>
              <td style={borderS}>OTROS INGRESOS</td>              
              <td style={borderNS}>{MXN.format(otrosI[0])}</td>
              <td style={borderNS}>{isNaN((otrosI[0]/ventas[0]).toFixed(2)) ? 0 : (otrosI[0]/ventas[0]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(otrosI[2])}</td>
              <td style={borderNS}>{isNaN((otrosI[2]/ventas[2]).toFixed(2)) ? 0 : (otrosI[2]/ventas[2]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(otrosI[1])}</td>
              <td style={borderNS}>{isNaN((otrosI[1]/ventas[1]).toFixed(2)) ? 0 : (otrosI[1]/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>              
              <td style={borderS}>OTROS GASTOS</td>
              <td style={borderNS}>{MXN.format(otrosG[0])}</td>
              <td style={borderNS}>{isNaN((otrosG[0]/ventas[0]).toFixed(2)) ? 0 : (otrosG[0]/ventas[0]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(otrosG[2])}</td>
              <td style={borderNS}>{isNaN((otrosG[2]/ventas[2]).toFixed(2)) ? 0 : (otrosG[2]/ventas[2]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(otrosG[1])}</td>
              <td style={borderNS}>{isNaN((otrosG[1]/ventas[1]).toFixed(2)) ? 0 : (otrosG[1]/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>              
              <td style={borderT}>UTILIDAD (PÉRDIDA) ANTES DE IMPUESTOS</td>              
              <td style={borderNT}>{MXN.format(ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0] + otrosI[0] - otrosG[0])}</td>
              <td style={borderNT}>{isNaN(((ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0] + otrosI[0] - otrosG[0])/ventas[0]).toFixed(2)) ? 0 : ((ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0] + otrosI[0] - otrosG[0])/ventas[0]).toFixed(2)}</td>
              <td style={borderNT}>{MXN.format(ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2] + otrosI[2] - otrosG[2])}</td>
              <td style={borderNT}>{isNaN(((ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2] + otrosI[2] - otrosG[2])/ventas[2]).toFixed(2)) ? 0 : ((ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2] + otrosI[2] - otrosG[2])/ventas[2]).toFixed(2)}</td>
              <td style={borderNT}>{MXN.format(ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1] + otrosI[1] - otrosG[1])}</td>
              <td style={borderNT}>{isNaN(((ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1] + otrosI[1] - otrosG[1])/ventas[1]).toFixed(2)) ? 0 : ((ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1] + otrosI[1] - otrosG[1])/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>              
              <td style={borderS}>ISR</td>              
              <td style={borderNS}>{MXN.format(isr[0])}</td>
              <td style={borderNS}>{isNaN((isr[0]/ventas[0]).toFixed(2)) ? 0 : (isr[0]/ventas[0]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(isr[2])}</td>
              <td style={borderNS}>{isNaN((isr[2]/ventas[2]).toFixed(2)) ? 0 : (isr[2]/ventas[2]).toFixed(2)}</td>
              <td style={borderNS}>{MXN.format(isr[1])}</td>
              <td style={borderNS}>{isNaN((isr[1]/ventas[1]).toFixed(2)) ? 0 : (isr[1]/ventas[1]).toFixed(2)}</td>
            </tr>
            <tr>              
              <td style={borderT}>UTILIDAD (PÉRDIDA) NETA</td>
              <td style={borderNT}>{MXN.format(ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0] + otrosI[0] - otrosG[0] - isr[0])}</td>
              <td style={borderNT}>{isNaN(((ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0] + otrosI[0] - otrosG[0] - isr[0])/ventas[0]).toFixed(2)) ? 0 : ((ventas[0] - costoV[0] - gastosF[0] + prodF[0] - gastosV[0] - gastosA[0] + otrosI[0] - otrosG[0] - isr[0])/ventas[0]).toFixed(2)}</td>
              <td style={borderNT}>{MXN.format(ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2] + otrosI[2] - otrosG[2] - isr[2])}</td>
              <td style={borderNT}>{isNaN(((ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2] + otrosI[2] - otrosG[2] - isr[2])/ventas[2]).toFixed(2)) ? 0 : ((ventas[2] - costoV[2] - gastosF[2] + prodF[2] - gastosV[2] - gastosA[2] + otrosI[2] - otrosG[2] - isr[2])/ventas[2]).toFixed(2)}</td>
              <td style={borderNT}>{MXN.format(ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1] + otrosI[1] - otrosG[1] - isr[1])}</td>
              <td style={borderNT}>{isNaN(((ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1] + otrosI[1] - otrosG[1] - isr[1])/ventas[1]).toFixed(2)) ? 0 : ((ventas[1] - costoV[1] - gastosF[1] + prodF[1] - gastosV[1] - gastosA[1] + otrosI[1] - otrosG[1] - isr[1])/ventas[1]).toFixed(2)}</td>
            </tr>            
          </tbody>
        </table>
        <div style={{margin: '50px'}}>
          <span style={{border: '1px solid white', borderCollapse: 'collapse', textAlign: 'left', fontWeight: '900', backgroundColor: 'rgb(86,144,149)', padding: '0 20px 0 20px'}}>UTILIDAD (PÉRDIDA) NETA ACUMULADA</span><span style={{margin: '0 20px 0 20px'}}>{MXN.format(acum)}</span>
        </div>       
      </div>                              
    </div>
  )
}

export default Ventas;
import React from 'react';

function Footer () {
  return(
    <footer style={{display: 'grid', gridTemplateColumns: 'auto auto auto',columnGap: '10px', height: '5rem', backgroundColor: 'rgb(52,67,86)', fontSize: '10px', fontFamily: 'proxima-nova'}}>      
      <div style={{margin: 'auto', color: 'white', textAlign: 'left', paddingLeft: '5px'}}>
          <p style={{marginBottom: '0'}}>Av. Miguel Ángel de Quevedo 287</p>
          <p style={{marginTop: '0'}}>Plaza la Estación CDMX 04310</p>        
      </div>
      <div style={{margin: 'auto'}}>        
          <img src={`${process.env.PUBLIC_URL}/Lupulo.png`} alt="lupulo" style={{width: '40px'}}/>
      </div>
      <div style={{margin: 'auto'}}>        
          <img src={`${process.env.PUBLIC_URL}/Logo-KasBlanco.png`} alt="lupulo" style={{width: '100px'}}/>
      </div>        
    </footer>
  )

}

export default Footer;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as qz from 'qz-tray';

import { useInput } from './useInput';
import { getActiveElement } from '@testing-library/user-event/dist/utils';

const tableCustom = function (data, options = {}) {
  options = options || { size: [], encoding: 'cp857' }
  let [width = 1, height = 1] = options.size || []
  let baseWidth = Math.floor(48 / width)
  let cellWidth = Math.floor(baseWidth / data.length)
  let leftoverSpace = baseWidth - cellWidth * data.length
  let lineStr = ''
  let secondLineEnabled = false
  let secondLine = []

  for (let i = 0; i < data.length; i++) {
    let obj = data[i]    
    let align = (obj.align || '').toUpperCase()
    let tooLong = false

    obj.text = obj.text.toString()
    let textLength = obj.text.length

    if (obj.width) {
      cellWidth = baseWidth * obj.width
    } else if (obj.cols) {
      cellWidth = obj.cols
    }

    if (cellWidth < textLength) {
      tooLong = true
      obj.originalText = obj.text
      obj.text = obj.text.substring(0, cellWidth)
    }

    if (align === 'CENTER') {
      let spaces = (cellWidth - textLength) / 2
      for (let s = 0; s < spaces; s++) {
        lineStr += ' '
      }

      if (obj.text !== '') {
        if (obj.style === "B") {
          lineStr += (
            '\x1b\x45\x01\x1b\x35\x1b\x2d\x00' +
            obj.text +
            '\x1b\x45\x00\x1b\x35\x1b\x2d\x00'
          )
        } else {
          lineStr += obj.text
        }
      }
      
      for (let s = 0; s < spaces - 1; s++) {
        lineStr += ' '
      }
    } else if (align === 'RIGHT') {
      let spaces = cellWidth - textLength
      if (leftoverSpace > 0) {
        spaces += leftoverSpace
        leftoverSpace = 0
      }

      for (let s = 0; s < spaces; s++) {
        lineStr += ' '
      }

      if (obj.text !== '') {
        if (obj.style === "B") {
          lineStr += (
            '\x1b\x45\x01\x1b\x35\x1b\x2d\x00' +
            obj.text +
            '\x1b\x45\x00\x1b\x35\x1b\x2d\x00'
          )
        } else {
          lineStr += obj.text
        }
      }
    } else {
      if (obj.text !== '') {
        if (obj.style === "B") {
          lineStr += (
            '\x1b\x45\x01\x1b\x35\x1b\x2d\x00' +
            obj.text +
            '\x1b\x45\x00\x1b\x35\x1b\x2d\x00'
          )
        } else {
          lineStr += obj.text
        }
      }

      let spaces = Math.floor(cellWidth - textLength)
      if (leftoverSpace > 0) {
        spaces += leftoverSpace
        leftoverSpace = 0
      }

      for (let s = 0; s < spaces; s++) {
        lineStr += ' '
      }
    }

    if (tooLong) {
      secondLineEnabled = true
      obj.text = obj.originalText.substring(cellWidth)      
      secondLine.push(obj)
    } else {
      obj.text = ''      
      secondLine.push(obj)
    }
  }

  // Set size to line
  // if (width > 1 || height > 1) {
  //   lineStr = (
  //     _.TEXT_FORMAT.TXT_CUSTOM_SIZE(width, height) +
  //     lineStr +
  //     _.TEXT_FORMAT.TXT_NORMAL
  //   )
  // }

  // Write the line  
    if (secondLineEnabled) {
    // Writes second line if has    
    return lineStr + "\n"  + tableCustom(secondLine, options)
  }    
    return lineStr + "\n"  
}

const formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN'
});

function Tickets () {
  const [ticket, setTicket]       = useState([]);
  const [botella, setBotella]     = useState([]);
  const [barril, setBarril]       = useState([]);
  const [comida, setComida]       = useState([]);
  const [comidaRes, setComidaRes] = useState([]);  
  const [modalW, setModalW]       = useState("none");
  const [modalC, setModalC]       = useState("none");
  const [handleTic, setHandleTic] = useState("");
  const [result, setResult]       = useState([]);
  const [barrelRes, setBarrelRes] = useState([]);
  const [add, setAdd]             = useState([]);
  const [index, setIndex]         = useState('');
  const [mesa, setMesa]           = useState('');
  const [init, setInit]           = useState('');
  const [month]             = useState(["ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic"]);

  const {value: nomSearch, bind: bindSearch, reset: resetSearch}            = useInput('');
  const {value: nomMesa, bind: bindMesa, reset: resetMesa}                  = useInput('');
  const {value: nomPersonas, bind: bindPersonas, reset: resetPersonas}      = useInput('');
  const {value: nomDesc, bind: bindDesc, reset: resetDesc}                  = useInput(0);
  const {value: nomProp, bind: bindProp, reset: resetProp}                  = useInput(0);
  const {value: nomIdent, bind: bindIdent, reset: resetIdent}               = useInput('');
  const MXN = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});
  
  var ticketActivo = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/ticketActivo`, {withCredentials: true})
    .then(response => {
      var tic = []
      var t = {};
      response.data.forEach(f => {
          var txt = Math.floor(f.mesa);                    
          t[txt] = (t[txt] || []).concat(f);          
      })
      Object.keys(t).forEach((key, index) => {
        let temp = {};
        let k = Object.keys(t)[index];
        temp[k] = t[key];
        tic.push(temp);
      });
            
      setTicket(tic);
    })
    .catch(err => {console.log(err)})
  }  

  useEffect(() => {
    ticketActivo();
  }, [])

   useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/allBeer`, {withCredentials: true})
    .then(response => {
      var fac = [];
      var bar = [];
      for(var f = 0; f < response.data.length; f++){
        if(response.data[f].cantidad === 0){
          console.log(response.data[f])
          continue
        }
        if(response.data[f].tipo !== "Barril"){                    
          fac.push(response.data[f])  
        } else {                    
          response.data[f].chico = 250
          response.data[f].mediano = 330
          response.data[f].grande = 500
          bar.push(response.data[f]) 
        }
      }
      setBotella(fac);
      setResult(fac);
      setBarril(bar);
      setBarrelRes(bar);
    })
    .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/carta`, {withCredentials: true})
    .then(response => {
      var comida  = [];   
      for(var f = 0; f < response.data.length; f++){
        comida.push(response.data[f])                
      }
      setComida(comida); 
      setComidaRes(comida);  
    })
    .catch(err => {console.log(err)})
  }, [])

  useEffect(() => {
    var res = botella.filter(bot => {
      let b = bot.nombre.toUpperCase();      
      return b.includes(nomSearch.toUpperCase());
    })
    var resB = barril.filter(bot => {
      let b = bot.nombre.toUpperCase();      
      return b.includes(nomSearch.toUpperCase());
    })
    var resC = comida.filter(bot => {
      let b = bot.nombre.toUpperCase();      
      return b.includes(nomSearch.toUpperCase());
    })
    setResult(res)
    setBarrelRes(resB)
    setComidaRes(resC)
  }, [nomSearch, botella, barril, comida])


    
  var addTicket = () => {    
    axios.post(`${process.env.REACT_APP_API_URL}/nuevoTicket`, {
      mesa: nomMesa, identificador: nomIdent, personas: nomPersonas, consumo: [], pago: "", total: 0, propina: 0, descuento: 0, granTotal: 0, activo: true, factura: ""
    }, {withCredentials: true})
    .then(response => {
      resetMesa();
      resetPersonas();
      resetIdent();
      ticketActivo();    
    })
    .catch(err => {console.log(err)})      
  }

  var order = (tic) => {
    setModalW("block");
    setHandleTic(tic);
  }  

  var orderClose = (tic, id, mesa, idCom ) => {
    setModalC("block");
    setIndex(idCom);
    setMesa(mesa);
    setInit(id)
    setHandleTic(tic);
  }

  var close = () => {
    setModalW("none");
    resetSearch();
    setAdd([]);
    setHandleTic("");
  }

  var closeC = () => {
    setModalC("none");    
    resetDesc();
    resetProp();
    setHandleTic("");
  }
  
  var addItem = (sku, nombre, precio, cantidad, tipo, factura, costo, proveedor, ingredientes) => {     
    var temp = [...add];    
    temp.push(
      {sku, nombre, precio, cantidad, tipo, facturaCompra: factura, costo, proveedor, ingredientes}
    )                 
    setAdd(temp);
  }

  var addComanda = () => {
    var send = [...add]; 
    for(var note = 0; note < add.length; note++){
      let n = document.getElementById(note).value
      send[note].notas = n;       
    }
    
    axios.put(`${process.env.REACT_APP_API_URL}/agregarComanda`, {ticket: handleTic, consumo: send}, {withCredentials: true})
    .then(response => {
      setModalW("none");
      resetSearch();
      setHandleTic("");
      setAdd([]);   
      ticketActivo();  
    })
    .catch(err => console.log(err))
  }

   var addDesc = (ticket, consumo) => {        
    console.log(ticket, consumo)
    axios.put(`${process.env.REACT_APP_API_URL}/agregarDesc`, {ticket, consumo}, {withCredentials: true})
    .then(response => {       
      ticketActivo();  
    })
    .catch(err => console.log(err))
  }
  

  var deleteItem = (id, idx) => {
    if(window.confirm(
        `¿SEGURO QUE QUIERE BORRAR EL ITEM?`
      )){
        axios.put(`${process.env.REACT_APP_API_URL}/delItem`, {id, idx}, {withCredentials: true})
        .then(response => {
          axios.put(`${process.env.REACT_APP_API_URL}/pullNull`, {id}, {withCredentials: true})
          .then(res => {
            ticketActivo();
          })
          .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
      } else {
          console.log("Operación cancelada")
          return
      }
  }

  var deleteTicket = (id) => {
      if(window.confirm(
        `¿SEGURO QUE QUIERE BORRAR EL TICKET: ${id}?`
      )){
        axios.post(`${process.env.REACT_APP_API_URL}/delTicket`, {id}, {withCredentials: true})
        .then(response => {
          ticketActivo()
        })
        .catch(err => console.log(err))
      } else {
          console.log("Operación cancelada")
          return
      }
    }

  var tic = (print) => {          
    var os = navigator.userAgent;
    console.log(os)
    var descuento = print.consumo.length > 0 ? print.consumo.reduce((acc, curr) => acc + curr.descuento, 0) ? print.consumo.reduce((acc, curr) => acc + curr.descuento, 0) : 0 : 0;
    var subtotal = print.consumo.length > 0 ? print.consumo.reduce((t, n) => t + n.precio, 0) : 0;
    var total = subtotal - descuento;
    qz.websocket.connect({usingSecure: false}).then(() => {      
      return qz.printers.find();    
    })
    .then((printer) => {
      console.log(printer)
      var config = qz.configs.create('Kas', {encoding: 'Cp1252'});
      var dater = new Intl.DateTimeFormat('es-MX', {
        hour: 'numeric',
        hour12: false,
        minute: 'numeric',    
        timeZone: 'America/Mexico_City',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      });
      var last = new Date();
      var data = [
        "\n" +
        "\n\x1B\x61\x31CERVECERIA KAS SAS DE CV" + 
        "\n\x1B\x61\x31M.A. QUEVEDO #287 04310" + 
        "\n" +
        `\n\x1B\x61\x31Tipo de pago: ${print.pago}` +
        "\n" +
        `\n\x1B\x61\x31Conusmo:` +
        "\n" +
        tableCustom(
          [
            {text: "#", align: "LEFT", width: 0.10, style:'B'},
            {text: 'Nom', align: "LEFT", width: 0.33, style:'B'},
            {text: "Precio", align: "RIGHT", width: 0.22, style:'B'}
          ], 
          {encoding: 'cp1252', size: [1,1]}
        ) +
         
        print.consumo.map((item, idx) => {
          return(
            tableCustom(
              [
                {text: `${item.cantidad}`, align: "LEFT", width: 0.10},
                {text: `${item.nombre}`, align: "LEFT", width: 0.30},
                {text: `${formatter.format(item.precio)}`, align: "RIGHT", width: 0.25}
              ],
              {encoding: 'cp1252', size: [1,1]}
            )
          )
        }).join('') +
        "\n" +
        tableCustom(
          [
            {text: "Descuento", align: "LEFT", width: 0.22},          
            {text: `${formatter.format(descuento)}`, align: "RIGHT", width: 0.22} 
          ], 
          {encoding: 'cp1252', size: [1,1]}
        ) +
        tableCustom(
          [
            {text: "Propina", align: "LEFT", width: 0.22},          
            {text: `${formatter.format(print.propina)}`, align: "RIGHT", width: 0.22}
          ], 
          {encoding: 'cp1252', size: [1,1]}
        ) +
        tableCustom(
          [
            {text: "TOTAL", align: "LEFT", width: 0.22, style: 'B'},          
            {text: `${formatter.format(total)}`, align: "RIGHT", width: 0.22, style:'B'}
          ], 
          {encoding: 'cp1252', size: [1,1]}
        ) +        
        "\n" +
        "\n" +
        "\n\x1B\x61\x31-------------------------" +
        `\n\x1b\x61\x00T.ID: ${print._id}` +
        "\n" +
        `\n\x1b\x61\x00Mesa: ${print.mesa}` +
        "\n" +
        `\n\x1b\x61\x00Instagram: /cerveceriakas` +
        "\n" +
        `\n\x1B\x61\x31${dater.format(last)}` +
        "\n" +    
        "\n" +
        `\n\x1b\x61\x00¿Cómo supiste de Kas?` +
        "\n" +           
        "\n" +   
        "\n" +
        `\n\x1b\x61\x00¿Por qué decidiste asistir?` +
        "\n" +
        "\n" +
        "\n" +  
        `\n\x1b\x61\x00¿Cumplió tus expectativas?` +
        "\n" +
        `\n\x1b\x61\x00Sí, porque: ` +
        "\n" +
        "\n" +
        "\n" +
        `\n\x1b\x61\x00No, porque:` +
        "\n" +
        "\n" +
        "\n" +
        `\n\x1b\x61\x00¿Cómo te parecieron los precios?` +
        "\n" +
        `\n\x1b\x61\x00__Baratos  __Normales  __Caros` +
        "\n" +
        `\n\x1b\x61\x00¿Cuál es tu canción favorita?` +
        "\n" +
        "\n" +
        "\n" +
        `\n\x1b\x61\x00¿A qué eventos te gusta asistir?` +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n" +
        "\n\x1d\x56\x00"      
      ];   // Raw commands (ZPL provided)
      qz.print(config, data)
      .then(qz.websocket.disconnect)
    })
    .catch(e => console.log(e));
  }

  var printOrder = (mesa, ident, orden, ticketID, cantidad) => {
    var os = navigator.userAgent;
    console.log(os)
    //Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Mobile Safari/537.36
    qz.websocket.connect({usingSecure: false}).then(() => {      
      return qz.printers.find();    
    })
    .then((printer) => {
      console.log(printer)
      var config = qz.configs.create('Kas', {encoding: 'Cp1252'});
      var dater = new Intl.DateTimeFormat('es-MX', {
        hour: 'numeric',
        hour12: false,
        minute: 'numeric',    
        timeZone: 'America/Mexico_City',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      });
      var last = new Date();
      var data = [
        "\n" +
        "\n\x1B\x61\x31CERVECERIA KAS SAS DE CV" + 
        "\n\x1B\x61\x31M.A. QUEVEDO #287 04310" + 
        "\n" +
        `\n\x1B\x61\x31Mesa: ${mesa}` +
        "\n" +
        `\n\x1B\x61\x31ID: ${ident}` +
        "\n" +
        `\n\x1b\x61\x00${cantidad} - ${orden}` +
        "\n" +
        "\n" +
        "\n\x1B\x61\x31-------------------------" +
        `\n\x1b\x61\x00T.ID: ${ticketID}` +
        "\n" +
        "\n" +
        `\n\x1B\x61\x31${dater.format(last)}` +
        "\n" +    
        "\n" +    
        "\n" +   
        "\n" +  
        "\n\x1d\x56\x00"      
      ];   // Raw commands (ZPL provided)
      qz.print(config, data)
      .then(qz.websocket.disconnect)
    })
    .catch(e => console.log(e));
  }

  var afectIng = (bulk) => {
     axios.put(`${process.env.REACT_APP_API_URL}/afectarIngredientes`, {bulk}, {withCredentials: true})
        .then(response => {
           console.log(response.status);
        })
        .catch(err => {console.log(err)})
  }

  var afectFact = (bulk) => {
     axios.put(`${process.env.REACT_APP_API_URL}/afectarFactura`, {bulk}, {withCredentials: true})
        .then(response => {
           console.log(response.status);
        })
        .catch(err => {console.log(err)})
  }

  var cerrarCuenta = () => {   
    var cuenta     = ticket[init][Math.floor(mesa)][index];    
    var total      = cuenta.consumo.reduce((t, n) => t + n.precio, 0);
    var costoTotal = cuenta.consumo.reduce((t, n) => t + n.costo, 0);
    var comida$    = cuenta.consumo.filter(f => f.tipo === "Comida").reduce((t, n) => t + (Number(n.precio) * Number(n.cantidad)), 0);
    var barril$    = cuenta.consumo.filter(f => f.tipo === "Barril").reduce((t, n) => t + n.precio, 0);
    var lata$      = cuenta.consumo.filter(f => f.tipo === "Lata").reduce((t, n) => t + (Number(n.precio) * Number(n.cantidad)), 0);
    var botella$   = cuenta.consumo.filter(f => f.tipo === "Botella").reduce((t, n) => t + (Number(n.precio) * Number(n.cantidad)), 0);
    var bebida$    = barril$ +  lata$ + botella$;
    var propina    = nomProp === "" || !nomProp ? 0 : parseFloat(nomProp);
    var descuento  = nomDesc === "" || !nomDesc ? 0 : parseFloat(nomDesc);
    var pago       = document.getElementById("tipoPago").value;
    var granTotal  = total - descuento + propina;
    var activo     = false;
    var bulkFood   = [];
    var bulkBeer   = [];
    var comidaNum  = cuenta.consumo.filter(f => f.tipo === "Comida");
    var bebidaNum  = cuenta.consumo.filter(f => f.tipo !== "Comida");
  
    for(var c = 0; c < cuenta.consumo.length; c++){      
      if(cuenta.consumo[c].tipo !== "Comida"){
        let nf = cuenta.consumo[c];          
        let temp = {'updateMany': {
              'filter': {'_id': nf.sku},
              'update': {
                '$inc': {"vendidas": nf.cantidad, "cantidad": nf.cantidad * -1},                
            }}          
          }     
          bulkBeer.push(temp);         
      } else {        
        let nw = cuenta.consumo[c];
        let plato = comida.filter(c => c.nombre === nw.nombre);
        for(var ing = 0; ing < plato[0].ingredientes.length; ing++){
          let este = plato[0].ingredientes[ing];
          let temp = {'updateMany': {
              'filter': {'nombre': este.insumo},
              'update': {'$inc': {"cantidad": este.gramajeUso * -1}
            }}          
          }         
          bulkFood.push(temp);
        }
      }      
    }

    bulkFood.length > 0 ? afectIng(bulkFood) : console.log('Sin comida');
    bulkBeer.length > 0 ? afectFact(bulkBeer) : console.log('Sin cerveza');
    axios.put(`${process.env.REACT_APP_API_URL}/cerrarComanda`, {ticket: handleTic, total, propina, descuento, pago, granTotal, activo, costoTotal}, {withCredentials: true})
    .then(response => {
      var tipo        = "Ingreso";            
      var cuentaCargo = "corto-Cuentas de resultados acreedoras (ingresos)-Ventas-Productos";
      var cuentaAbono = pago === "Efectivo" ? "corto-Activo-Caja-MA Quevedo" : "corto-Activo-Bancos-Santander";                  
      var ctaAbono    = "corto-Cuentas de resultados deudoras (gastos)-Costo de Ventas-Productos";                
      var concepto    = `Venta de productos en tienda. Comida: ${comida$}, Bebidas: ${bebida$}`;
      var monto       = (total - descuento) * 0.84;
      var iva         = (total - descuento) * 0.16;
      var referencia  = handleTic;
      var rfc         = "";
      var fecha       = new Date();                                              
      var ivaCta      = "corto-Pasivo-IVA deudor-IVA trasladado";                                          
      
      axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo, cuentaAbono, concepto, monto, referencia, rfc, fecha}, {withCredentials: true})
      .then(r => {          
      })              
      .catch(err => console.log(err));

      axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: ivaCta, cuentaAbono, concepto, monto: iva, referencia, rfc, fecha}, {withCredentials: true})
      .then(r => {          
      })              
      .catch(err => console.log(err));
      
      for(var cv = 0; cv < bebidaNum.length; cv++){        
        var ctaCargo    = "corto-Activo-Almacén-" + bebidaNum[cv].proveedor;
        axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: ctaCargo, cuentaAbono: ctaAbono, concepto, monto: bebidaNum[cv].costo, referencia, rfc, fecha}, {withCredentials: true})
        .then(r => {            
        })              
        .catch(err => console.log(err));
      }

      for(var cvc = 0; cvc < comidaNum.length; cvc++){
        var ings = comidaNum[cvc].ingredientes;
        for(var lcvc = 0; lcvc < ings.length; lcvc++){
          var ctCargo    = "corto-Activo-Almacén-" + ings[lcvc].marca;
          axios.post(`${process.env.REACT_APP_API_URL}/poliza`, {tipo, cuentaCargo: ctCargo, cuentaAbono: ctaAbono, concepto, monto: ings[lcvc].costoUso, referencia, rfc, fecha}, {withCredentials: true})
          .then(r => {              
          })              
          .catch(err => console.log(err));
        }        
      }

      axios.get(`${process.env.REACT_APP_API_URL}/cuentas`, {withCredentials: true})
      .then(res => {
        var cuentas     = res.data;           
        var efec        = pago === "Efectivo" ? true : false;
        var act         = cuentas.filter(f => f.clase === "Activo"); 
        var posBan      = act[0].corto.map(m => m.cuenta).indexOf("Bancos");   
        var posSan      = act[0].corto[posBan].subcuentas.map(m => m.subcuenta).indexOf("Santander");

        var posCaj      = act[0].corto.map(m => m.cuenta).indexOf("Caja");   
        var posLoc      = act[0].corto[posCaj].subcuentas.map(m => m.subcuenta).indexOf("MA Quevedo");  

        var posAlm      = act[0].corto.map(m => m.cuenta).indexOf("Almacén");        

        var ing         = cuentas.filter(f => f.clase === "Cuentas de resultados acreedoras (ingresos)"); 
        var posVta      = ing[0].corto.map(m => m.cuenta).indexOf("Ventas"); 
        var posPro      = ing[0].corto[posVta].subcuentas.map(m => m.subcuenta).indexOf("Productos"); 

        var egr         = cuentas.filter(f => f.clase === "Cuentas de resultados deudoras (gastos)"); 
        var posCtVta    = egr[0].corto.map(m => m.cuenta).indexOf("Costo de Ventas"); 
        var posProC     = egr[0].corto[posCtVta].subcuentas.map(m => m.subcuenta).indexOf("Productos");  

        var pas         = cuentas.filter(f => f.clase === "Pasivo");            
        var posIVAc     = pas[0].corto.map(m => m.cuenta).indexOf("IVA deudor");
        var posIVAsc    = pas[0].corto[posIVAc].subcuentas.map(m => m.subcuenta).indexOf("IVA trasladado");

        var ctaAbn  = `corto-Cuentas de resultados deudoras (gastos)-Costo de Ventas-Productos-${posProC}-${posCtVta}`
                                                    
        var abono = efec ? `corto-Activo-Caja-MA Quevedo-${posLoc}-${posCaj}` : `corto-Activo-Bancos-Santander-${posSan}-${posBan}`
        var cargo = `corto-Cuentas de resultados acreedoras (ingresos)-Ventas-Productos-${posPro}-${posVta}`
        var abIVA = `corto-Pasivo-IVA deudor-IVA Trasladado-${posIVAsc}-${posIVAc}`
                                     
        axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo, abono, monto}, {withCredentials: true})
        .then(rpn => {          
        })
        .catch(err => console.log(err));
                
        axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo: abIVA, abono, monto: iva}, {withCredentials: true})
        .then(rpn => {          
        })
        .catch(err => console.log(err));       
        
        for(var cv = 0; cv < bebidaNum.length; cv++){  
          var posInv  = act[0].corto[posAlm].subcuentas.map(m => m.subcuenta).indexOf(bebidaNum[cv].proveedor);                    
          var ctaCarg = `corto-Activo-Almacén-Subcuenta-${posInv}-${posAlm}`           
          
          axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo: ctaCarg, abono: ctaAbn, monto: bebidaNum[cv].costo}, {withCredentials: true})
          .then(rpn => {            
          })
          .catch(err => console.log(err));
        }

        for(var ccv = 0; ccv < comidaNum.length; ccv++){ 
          for(var cing = 0; cing < comidaNum[ccv].ingredientes.length; cing++){
            var posIn   = act[0].corto[posAlm].subcuentas.map(m => m.subcuenta).indexOf(comidaNum[ccv].ingredientes[cing].marca);                    
            var ctaCar  = `corto-Activo-Almacén-Subcuenta-${posIn}-${posAlm}`                        
            
            axios.put(`${process.env.REACT_APP_API_URL}/movimientos`, {cargo: ctaCar, abono: ctaAbn, monto: comidaNum[ccv].ingredientes[cing].costoUso}, {withCredentials: true})
            .then(rpn => {              
            })
            .catch(err => console.log(err));
          }                      
        }
      })

      setModalC("none");
      resetProp(0);
      resetDesc(0);
      setHandleTic("");      
      ticketActivo();  
    })
    .catch(err => {console.log(err)})
  }

  var toggle = (id) => {
    let m = document.getElementById(id);
    if(m.style.display === 'block'){
      m.style.display = 'none';
    } else {
      m.style.display = 'block';
    }
  }

  return(
    <div style={{marginBottom: '0', fontFamily: 'Roxborough'}}>      
      <div className='modal' style={{display: modalW, position: 'absolute', zIndex: 1, backgroundColor: 'rgba(0,0,0,.25)', height: '100%', padding: '50%', top: '0'}}>
        <div className='modal-content' style={{backgroundColor: 'white', position: 'absolute', height: '50%', display: 'block', overflow: 'scroll', top: '5%', width: '80%', left: '10%', borderRadius: '15px'}}>
          <div style={{textAlign: 'right', margin: '20px'}}>
            <p></p>
            <span className='close' style={{backgroundColor: 'rgb(52, 67, 86)', color: 'white', fontWeight: 'bold', borderRadius: '30px', padding: '2px 6px', top: '100px', cursor: 'pointer'}} onClick={() => close()}>&times;</span>          
          </div>
          <div>
            <h1>Agregar producto a comanda</h1>            
            <div>
              <p>Items por agregar:</p>  
              <table style={{width: '80%', margin: 'auto', padding: '0 5% 0 5%'}}>
                <thead>
                  <tr style={{backgroundColor: 'rgba(86, 144, 149, .7'}}>
                    <th style={{textAlign: 'left'}}>Nombre</th>
                    <th>Cantidad</th>
                    <th style={{textAlign: 'left'}}>Notas</th>
                  </tr>
                </thead>
                <tbody>            
                {add ? add.map((item, idx) => {
                  return(
                    <tr key={idx}>
                      <td>{item.nombre}</td>
                      <td>{item.cantidad}</td>
                      <td>
                        <input id={idx} type="text"/>
                      </td>
                    </tr>                                                                  
                  )
                }) : null} 
               </tbody>
              </table>
              <button style={{borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#344356', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => addComanda()}>Agregar items</button>                
            </div>
            <p>Handle {handleTic}</p>
            <label>Buscar: </label><input placeholder="Nombre" type="text" {...bindSearch}/>
            <table style={{width: '80%', margin: 'auto', padding: '2% 5% 5% 5%'}}>
              <thead>
                <tr style={{backgroundColor: 'rgba(86, 144, 149, .7'}}>
                  <th style={{textAlign: 'left'}}>Nombre</th>
                  <th>Tipo</th>
                  <th>Precio</th>
                  <th>Agregar</th>                  
                </tr>
              </thead>
              <tbody>                
                {result ? result.map(res => {                  
                  return(
                    <tr key={res._id}>
                      <td style={{textAlign: 'left'}}>{res.nombre} - {res.cervecera}</td>
                      <td>{res.tipo}</td>
                      <td>{MXN.format(res.precio)}</td>
                      <td>
                        <button style={{cursor: 'pointer', borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#344356', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => addItem(res._id, res.nombre, res.precio, 1, res.tipo, res.factura, res.costo, res.proveedor)}>Seleccionar</button>                
                      </td>
                    </tr>                    
                  )
                }) : null}
                {barrelRes ? barrelRes.map((res, idx) => {
                  return(
                    <tr key={idx}>
                      <td  style={{textAlign: 'left'}}>{res.nombre} - {res.cervecera}</td>
                      <td>{res.tipo} - {res.chico}</td>
                      <td>{MXN.format((res.precio/res.cotiza)*res.chico)}</td>
                      <td>
                        <button style={{cursor: 'pointer', borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#344356', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => addItem(res._id, res.nombre, ((res.precio/res.cotiza)*res.chico), res.chico, res.tipo, res.factura, ((res.costo/res.cotiza)*res.chico), res.proveedor, [])}>Seleccionar</button>                
                      </td>
                    </tr>
                  )
                }) : null}
                {barrelRes ? barrelRes.map((res, idx) => {
                  return(
                    <tr key={idx}>
                      <td style={{textAlign: 'left'}}>{res.nombre} - {res.cervecera}</td>
                      <td>{res.tipo} - {res.mediano}</td>
                      <td>{MXN.format((res.precio/res.cotiza)*res.mediano)}</td>
                      <td>
                        <button style={{cursor: 'pointer', borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#344356', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => addItem(res._id, res.nombre, ((res.precio/res.cotiza)*res.mediano), res.mediano, res.tipo, res.factura, ((res.costo/res.cotiza)*res.mediano), res.proveedor, [])}>Seleccionar</button>                
                      </td>
                    </tr>
                  )
                }) : null}
                {barrelRes ? barrelRes.map((res, idx) => {
                  return(
                    <tr key={idx}>
                      <td style={{textAlign: 'left'}}>{res.nombre} - {res.cervecera}</td>
                      <td>{res.tipo} - {res.grande}</td>
                      <td>{MXN.format((res.precio/res.cotiza)*res.grande)}</td>
                      <td>
                        <button style={{cursor: 'pointer', borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#344356', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => addItem(res._id, res.nombre, ((res.precio/res.cotiza)*res.grande), res.grande, res.tipo, res.factura, ((res.costo/res.cotiza)*res.grande), res.proveedor, [])}>Seleccionar</button>                
                      </td>
                    </tr>
                  )
                }) : null}
                {comidaRes ? comidaRes.map((res, idx) => {
                  return(
                    <tr key={idx}>
                      <td style={{textAlign: 'left'}}>{res.nombre}</td>
                      <td>Comida</td>
                      <td>{MXN.format(res.precio)}</td>
                      <td>
                        <button style={{cursor: 'pointer', borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#344356', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => addItem(res._id, res.nombre, res.precio, 1, "Comida", 0, res.costo, "", res.ingredientes)}>Seleccionar</button>                
                      </td>
                    </tr>
                  )
                }) : null}
              </tbody>
            </table>          
          </div>
        </div>             
      </div> 
      <div className='modal' style={{display: modalC, position: 'absolute', zIndex: 1, backgroundColor: 'rgba(0,0,0,.25)', height: '100%', padding: '50%', top: '0'}}>
        <div className='modal-content' style={{backgroundColor: 'white', position: 'absolute', height: '30%', display: 'block', overflow: 'scroll', top: '5%', width: '80%', left: '10%', borderRadius: '15px'}}>
          <div style={{textAlign: 'right', margin: '20px'}}>
            <p></p>
            <span className='close' style={{backgroundColor: 'rgb(52, 67, 86)', color: 'white', fontWeight: 'bold', borderRadius: '30px', padding: '2px 6px', top: '100px', cursor: 'pointer'}} onClick={() => closeC()}>&times;</span>          
          </div>
          <div>
            <h1>Cerrar cuenta</h1>                       
            <p>Ticket {handleTic}</p>
            <label>Decuento: </label><input placeholder="Descuento" type="number" {...bindDesc}/>
            <label>Propina: </label><input placeholder="Propina" type="number" {...bindProp}/>
            <label>Tipo de pago: </label><select id="tipoPago" name="tipoPago">
              <option value="Tarjeta">Tarjeta</option>
              <option value="Efectivo">Efectivo</option>
              <option value="Transferencia">Transferencia</option>
              <option value="Tarjeta y Transferencia">Tarjeta y Transfer</option>
              <option value="Efectivo y Transferencia">Efectivo y Transfer</option>
              <option value="Tarjeta y Efectivo">Tarjeta y Efectivo</option>
            </select>
            <button style={{cursor: 'pointer', borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#344356', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => cerrarCuenta()}>Cerrar</button>
          </div>
        </div>  
      </div>        
      <div>
        <div>
          <h1>Nuevo Ticket</h1>
          <input type="number" min={1} max={17} style={{width: '10rem'}} placeholder='mesa' {...bindMesa}/>
          <input type="number" min={1} placeholder='personas' {...bindPersonas}/>
          <input type="text" placeholder='Identificador' {...bindIdent}/>
          <button onClick={() => addTicket()}>Agregar</button>
        </div>
        <h1>Tickets activos</h1> 
        {ticket ? ticket.map((t, id) => {          
          var key = Object.keys(t);          
          return(
            <div key={id}>
               <button className="accordion" onClick={() => toggle(`mesa${key}`)} style={{
                  backgroundColor: '#eee', 
                  color: '#444',
                  cursor: 'pointer',
                  padding: '18px',
                  width: '100%',
                  textAlign: 'left',
                  border: 'none',
                  outline: 'none',
                  transition: '0.4s',                  
                  }}>Mesa {key}</button>
                <div className="panel" id={`mesa${key}`} style={{
                  padding: '0 18px',
                  backgroundColor: 'white',
                  display: 'none', 
                  overflow: 'hidden'
                }}>
                  <div style={{display: 'inline-block'}}>
                    {t[key].map((comanda, idxCom) => {
                      let subtotal = comanda.consumo.length > 0 ? comanda.consumo.reduce((t, n) => t + n.precio, 0) : 0;
                      let fecha = new Date(comanda.createdAt);
                      let dia = fecha.getDate();
                      let mes = fecha.getMonth();
                      let año = fecha.getFullYear();
                      let hora = fecha.getHours();
                      let min = fecha.getMinutes();
                      return(
                        <div id={comanda._id} key={comanda._id} style={{ borderRadius: '10px', margin: '2rem', textAlign: 'left'}}>
                          <div style={{backgroundColor: 'rgba(230,223,210,.5)'}}>
                            <table style={{width: '100%', margin: 'auto'}}>
                              <thead>
                                <tr style={{backgroundColor: 'rgba(86, 144, 149, .7'}}>
                                  <th>Mesa</th>
                                  <th>Personas</th> 
                                  <th>Ticket</th>
                                </tr>                      
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{comanda.mesa}</td>
                                  <td>{comanda.personas}</td>
                                  <td>{comanda._id}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table style={{width: '100%', margin: 'auto'}}>
                              <thead>
                                <tr style={{backgroundColor: 'rgba(86, 144, 149, .7'}}>
                                  <th>Identificador</th>
                                  <th>Fecha y Hora</th>                        
                                </tr>                      
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{comanda.identificador}</td>
                                  <td>{dia}/{month[mes]}/{año} - {hora}:{min}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table style={{width: '100%', marginBottom: '2rem'}}>
                              <thead>
                                <tr style={{backgroundColor: 'rgba(86, 144, 149, .7'}}>                        
                                  <th>Subtotal</th>
                                  <th>Descuento</th>
                                  <th>Propina</th>
                                  <th>Total</th>
                                </tr>                      
                              </thead>
                              <tbody>
                                <tr>                        
                                  <td>{MXN.format(subtotal)}</td>
                                  <td>{MXN.format(comanda.consumo.length > 0 ? comanda.consumo.reduce((acc, curr) => acc + curr.descuento, 0) ? comanda.consumo.reduce((acc, curr) => acc + curr.descuento, 0) : 0 : 0)}</td>
                                  <td>{MXN.format(comanda.propina)}</td>
                                  <td>{MXN.format(subtotal - comanda.descuento + comanda.propina)}</td>
                                </tr>
                              </tbody>
                            </table>
                            <table style={{width: '100%', marginBottom: '4rem'}}>
                              <thead>
                                <tr style={{backgroundColor: 'rgba(86, 144, 149, .7'}}>                        
                                  <th>Borrar</th>
                                  <th>Cantidad</th>
                                  <th>Nombre</th>
                                  <th>Precio</th>
                                  <th>Descuento</th>
                                  <th>Print</th>                                  
                                </tr>                      
                              </thead>
                              <tbody>                                
                                {comanda.consumo ? comanda.consumo.map((cons, idx) => {
                                  var desc = {};
                                  desc[`${idx}-${comanda._id}`] = 0;                                  
                                  var precFinal = cons.descuento? cons.precio - cons.descuento : cons.precio;
                                  var cc = comanda.consumo;
                                  return(
                                    <tr key={idx}>
                                      <td>
                                        <button style={{border: 'none', cursor: 'pointer'}} onClick={() => deleteItem(comanda._id, idx)}>
                                           <div className="icon-trash" style={{margin: 'auto'}}>
                                              <div className="trash-lid" style={{backgroundColor: "#B22222"}}></div>
                                              <div className="trash-container" style={{backgroundColor: "#B22222"}}></div>
                                              <div className="trash-line-1"></div>
                                              <div className="trash-line-2"></div>
                                              <div className="trash-line-3"></div>
                                            </div>
                                        </button>
                                      </td>                            
                                      <td>{cons.cantidad}</td>
                                      <td>{cons.nombre} - {cons.notas}</td>
                                      <td>{MXN.format(precFinal)}</td>
                                      <td>                                        
                                        <input type="number" placeholder={cons.descuento} id={`${idx}-${comanda._id}`} onChange={(r) => cc[idx].descuento = Number(r.target.value)}/>
                                        <button onClick={() => addDesc(comanda._id, cc)}>Agregar </button>
                                      </td>
                                      <td>
                                        <button style={{border: 'none', cursor: 'pointer'}} onClick={() => printOrder(comanda.mesa, comanda.identificador, `${cons.nombre} - ${cons.notas}`, comanda._id, cons.cantidad)}>                                                                                    
                                          <div className="icon-printer" style={{margin: 'auto'}}>
                                            <div className="printer-body" style={{backgroundColor: '#000000'}}></div>
                                            <div className="printer-top"></div>
                                            <div className="printer-bottom"></div>
                                            <div className="printer-file-top" style={{backgroundColor: '#808080'}}></div>                                            
                                            <div className="printer-line" style={{left: '50%', top: '32%', marginLeft: '-12%'}}></div>
                                            <div className="printer-line" style={{left: '50%', top: '42%', marginLeft: '-12%'}}></div>
                                            <div className="printer-file-bottom" style={{backgroundColor: '#F8F8FF'}}></div>
                                          </div>                                         
                                        </button>
                                      </td>                                      
                                    </tr>
                                  )
                                }) : null}                      
                              </tbody>
                            </table>
                            <div>
                              <button style={{borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: 'red', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => deleteTicket(comanda._id)}>Borrar Ticket</button>
                              <button style={{borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#c5dde2', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => order(comanda._id)}>Agregar orden</button>
                              <button style={{borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#569095', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => tic(comanda)}>Imprimir ticket</button>
                              <button style={{borderRadius: '5px', padding: '5px', fontWeight:'bold', backgroundColor: '#344356', color: 'white', margin: '5px', border: 'solid 1px'}} onClick={() => orderClose(comanda._id, id, comanda.mesa, idxCom )}>Cerrar cuenta</button>
                            </div>                  
                          </div>                              
                        </div>                    
                      )
                    })}
                  </div>                  
                </div>
            </div>
          )
        }) : null}        
      </div>            
    </div>
  )
}

export default Tickets;
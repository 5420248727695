import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Perfil () {  
  const [perfil, setPerfil] = useState('');
  const {id}                = useParams();
  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/userProfile`, {withCredentials: true})
    .then(response => {      
    })
  }, [])



  return(
    <div>
      <div style={{backgroundColor: 'lightgrey'}}>
        <h1>Página en construcción</h1>        
      </div>      
      <div style={{height: "45rem"}}>
        <img style={{filter: 'grayscale(100%)',width: "100%", top: '10rem', left: '0rem', position: 'absolute', zIndex: '-1'}} src="https://res.cloudinary.com/dymvfpmu4/image/upload/v1691124560/WhatsApp_Image_2023-08-03_at_10.36.06_PM.jpg" alt="construccion" />
      </div>      
      
     
    </div>
  )

}

export default Perfil;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Facturas () {
  const [facts, setFacts]   = useState([]);
  const [filto, setFiltro]  = useState([]);
  const [filinv, setFilinv] = useState([]);
  const [facti, setFacti]   = useState('block');
  const [inv, setInv]       = useState('none');
  const [month]             = useState(["ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic"]);
  const [border]            = useState({border: '1px solid'})
  const [thStyle]           = useState({
    paddingTop: '12px',
    paddingBottom: '12px',
    textAlign: 'left',
    backgroundColor: '#c5dde2',
    color: 'black',
    border: '1px solid #ddd',
    padding: '8px',
  })

  const MXN = new Intl.NumberFormat('es-MX', {style: 'currency', currency: 'MXN'});
  const Num = new Intl.NumberFormat();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/all`, {withCredentials: true})
    .then(response => {
      setFacts(response.data);
      setFiltro(response.data);
      setFilinv(response.data);
    })
    .catch(err => {console.log(err)})
  }, [])
  console.log(facts)

  var filtro = (e) => {
    var search = e.target.value.toUpperCase()
    var res = facts.filter(f => f.proveedor.toUpperCase().includes(search))    
    setFiltro(res);
  }

  var filtroNom = () => {    
    var v = {
      nom: document.getElementById('nom').value.toUpperCase(), 
      cerv: document.getElementById('cerv').value.toUpperCase(), 
      tip: document.getElementById('tip').value.toUpperCase(), 
      est: document.getElementById('est').value.toUpperCase(), 
      ex: document.getElementById('ex').value
    }
    console.log(v)
    var res = facts.map(f => {
      return {...f, cerveza: f.cerveza.filter(sf => sf.nombre.toUpperCase().includes(v.nom) && sf.cervecera.toUpperCase().includes(v.cerv) && sf.tipo.toUpperCase().includes(v.tip) && sf.estilo.toUpperCase().includes(v.est) && (v.ex === 'Todo' ? Math.sign(sf.cantidad - sf.vendidas) > -2 : Math.sign(sf.cantidad - sf.vendidas) === parseInt(v.ex)))}
    })    
    setFilinv(res);
  }

  return(
    <div style={{marginBottom: '0', fontFamily: 'Roxborough'}}>     
      <div style={{margin: '20px'}}>
        <button style={{width: '50%',backgroundColor: facti === 'block' ? 'rgb(52,67,86)' : 'rgb(197,221,226)', fontWeight: 'bold', fontSize: '25px', padding: '5px', border: 'none', cursor: 'pointer', color: facti === 'block' ? 'white' : 'black'}} onClick={() => {setFacti('block'); setInv('none')}}>Facturas</button>
        <button style={{width: '50%',backgroundColor: inv === 'block' ? 'rgb(52,67,86)' : 'rgb(197,221,226)', fontWeight: 'bold', fontSize: '25px', padding: '5px', border: 'none', cursor: 'pointer', color: inv === 'block' ? 'white' : 'black'}} onClick={() => {setFacti('none'); setInv('block')}}>Inventario</button>
      </div>         
      <div style={{display: facti}}>
        <label>Por proveedor: </label>
        <input onChange={(e) => filtro(e)} type="text" placeholder='Proveedor'/>
        <p>Valor solicitado: {MXN.format(filto.reduce((total, t) => total + t.valor, 0))}</p>
        <p>Pago pendiente: {MXN.format(filto.reduce((total, t) => total + (t.estatusPago === "Pendiente" ? t.valor : 0), 0))}</p>
        {filto ? filto.map(factura => {
          var entrega = new Date(factura.entrega);
          var eD      = entrega.getDate();
          var eM      = entrega.getMonth();
          var eY      = entrega.getFullYear().toString().slice(-2);
          var vencimiento = new Date(entrega.setDate(entrega.getDate() + factura.credito));        
          var vD      = vencimiento.getDate();
          var vM      = vencimiento.getMonth();
          var vY      = vencimiento.getFullYear();                
          return(
            <div key={factura._id} style={{border: "2px solid black", borderRadius: '5px', margin: '10px', textAlign: 'left', padding: '2rem'}}>
              <h3>Proveedor: {factura.proveedor}</h3>
              <p><strong>Fecha de vencimiento de pago:</strong> {vD + "/" + month[vM] + "/" + vY}</p>
              <p><strong>Pago:</strong> {factura.estatusPago}</p>
              <table style={{width: '100%', textAlign: 'center', borderCollapse: 'collapse'}}>
                <thead>
                  <tr>
                    <th style={border}>Folio</th>
                    <th style={border}>Entrega</th>
                    <th style={border}>Crédito</th>
                    <th style={border}>Valor</th>                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={border}>{factura.folio}</td>
                    <td style={border}>{eD + "/" + month[eM] + "/" + eY}</td>
                    <td style={border}>{factura.credito} días</td>
                    <td style={border}>{MXN.format(factura.valor)}</td>                  
                  </tr>
                </tbody>
              </table>                                         
              <p>Cervezas vendidas: {factura.vtaCervezas} de {factura.numCervezas}</p>
              <div style={{overflowX: 'auto'}}>            
                <table style={{width: '100%'}}>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Nom</th>
                      <th>Cto</th>
                      <th>IVA</th>
                      <th>IEPS</th>
                      <th>Neto</th>
                      <th>#</th>
                      <th>Vta</th>
                      <th>Tot</th>
                    </tr>
                  </thead>
                  <tbody>                
                    {factura.cerveza ? factura.cerveza.map((c, idx) => {
                      return(                      
                        <tr key={idx}>
                          <td>{c.tipo}</td>
                          <td>{c.nombre}</td>
                          <td>{MXN.format(c.costo)}</td>
                          <td>{MXN.format(c.iva)}</td>
                          <td>{MXN.format(c.ieps)}</td>
                          <td>{MXN.format(c.costo + c.iva + c.ieps)}</td>
                          <td>{Num.format(c.cantidad)}</td> 
                          <td>{Num.format(c.vendidas)}</td>
                          <td>{c.tipo === "Barril" ? MXN.format(c.costo + c.iva + c.ieps) : MXN.format((c.costo + c.iva + c.ieps) * c.cantidad)}</td>
                        </tr>
                      )
                    }): null}
                    {factura.merch ? factura.merch.map((c, idx) => {
                      return(                      
                        <tr key={idx}>
                          <td>merch {c.capacidad}</td>
                          <td>{c.producto}</td>
                          <td>{MXN.format(c.costo)}</td>
                          <td>{MXN.format(0)}</td>
                          <td>{MXN.format(0)}</td>
                          <td>{MXN.format(c.costo)}</td>
                          <td>{c.cantidad}</td> 
                          <td>0</td>
                          <td>{MXN.format((c.costo) * c.cantidad)}</td>
                        </tr>
                      )
                    }): null}
                  </tbody>
                </table>
              </div>
            </div>
          )
        }) : null}
      </div>
      <div style={{display: inv}}>      
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label>Por nombre: </label>
          <input id='nom' onChange={() => filtroNom()} type="text" placeholder='Nombre'/>
        </div>
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label>Por cervecera: </label>
          <input id='cerv' onChange={() => filtroNom()} type="text" placeholder='Cervecera'/>
        </div>
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label>Por estilo: </label>
          <input id='est' onChange={() => filtroNom()} type="text" placeholder='Estilo'/>
        </div>        
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label>Por tipo: </label>
          <input id='tip' onChange={() => filtroNom()} type="text" placeholder='Tipo'/>
        </div>        
        <div style={{textAlign: 'left', margin: '10px'}}>
          <label>Por existencias: </label>
          <select id='ex' onChange={() => filtroNom()}>
            <option value="Todo">Todo</option>
            <option value='0'>En cero</option>
            <option value="1">Positivas</option>
            <option value="-1">Negativas</option>
          </select>        
        </div> 
        <div style={{textAlign: 'left', margin: '10px'}}>
          <table style={{width: '100%', textAlign: 'center', borderCollapse: 'collapse'}}>
            <thead>
              <tr>
                <th style={border}>Existencias en lata</th>
                <th style={border}>Existencias en botella</th>
                <th style={border}>ML disponibles en barril</th>
                <th style={border}>Uds vendidas en lata/botella</th>
                <th style={border}>Ventas (ml) barril</th>                  
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={border}>{Num.format(filinv.length > 0 ? filinv.reduce((tot, bot) => tot + bot.cerveza.reduce((t, b) => t + (b.tipo === 'Lata' ? (b.cantidad - b.vendidas) : 0), 0), 0) : null)}</td>
                <td style={border}>{Num.format(filinv.length > 0 ? filinv.reduce((tot, bot) => tot + bot.cerveza.reduce((t, b) => t + (b.tipo === 'Botella' ? (b.cantidad - b.vendidas) : 0), 0), 0) : null)}</td>
                <td style={border}>{Num.format(filinv.length > 0 ? filinv.reduce((tot, bot) => tot + bot.cerveza.reduce((t, b) => t + (b.tipo === 'Barril' ? (b.cantidad - b.vendidas) : 0), 0), 0) : null)}</td>
                <td style={border}>{Num.format(filinv.length > 0 ? filinv.reduce((tot, bot) => tot + bot.cerveza.reduce((t, b) => t + (b.tipo === 'Botella' || b.tipo === 'Lata' ? (b.vendidas) : 0), 0), 0) : null)}</td>                  
                <td style={border}>{Num.format(filinv.length > 0 ? filinv.reduce((tot, bot) => tot + bot.cerveza.reduce((t, b) => t + (b.tipo === 'Barril' ? (b.vendidas) : 0), 0), 0) : null)}</td>
              </tr>
            </tbody>
          </table> 
        </div>       
        <div style={{overflowX: 'auto'}}>            
          <table style={{width: '100%', textAlign: 'left', padding: '10px'}}>
            <thead>
              <tr>
                <th style={thStyle}>Fecha entrada</th>
                <th style={thStyle}>Nombre</th>
                <th style={thStyle}>Cervecera</th>
                <th style={thStyle}>Estilo</th>
                <th style={thStyle}>Proveedor</th>
                <th style={thStyle}>Tipo</th>
                <th style={thStyle}>Ingresos (uds)</th>
                <th style={thStyle}>Venta (uds)</th>
                <th style={thStyle}>PVP</th>  
                <th style={thStyle}>Activo</th>
                <th style={thStyle}>Existencias</th>                                          
              </tr>
            </thead>            
            {filinv ? filinv.map((factura, idf) => {
              var entrega = new Date(factura.entrega);
              var eD      = entrega.getDate();
              var eM      = entrega.getMonth();
              var eY      = entrega.getFullYear().toString().slice(-2); 
              var colPar  = '#f2f2f2';
              var colNon  = 'white';
              var anteM   = filinv[idf-2] && filinv[idf-2].merch.length > 0 ? filinv[idf-2].merch.length : "next";
              var anteC   = filinv[idf-2] && filinv[idf-2].cerveza.length > 0 ? filinv[idf-2].cerveza.length : "next";
              var antM    = filinv[idf-1] && filinv[idf-1].merch.length > 0 ? filinv[idf-1].merch.length : "next";
              var antC    = filinv[idf-1] && filinv[idf-1].cerveza.length > 0 ? filinv[idf-1].cerveza.length : "next";
              var antPar  = antC === "next" && antM === "next" ? true : antC === "next" ? antM % 2 === 0 ? true : false : antC % 2 === 0 ? true : false;
              var antePar = anteM === "next" && anteC === "next" ? true : anteM === "next" ? anteC % 2 === 0 ? true : false : anteM % 2 === 0 ? true : false;
              console.log(filinv[idf-1])                            
              return(            
                <tbody key={idf}>
                {factura.cerveza ? factura.cerveza.map((c, idx) => {                  
                  let s = factura.cerveza.length;
                  let par = s % 2 === 0 ? true : false;
                  let colour = (idf === 0) ? (
                    (idx + 1) % 2 === 0 ? colPar : colNon
                  ) : (idf === 1 ? (
                    !antPar && par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : !antPar && !par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : antPar && !par ? ((idx + 1) % 2 === 0 ? colPar : colNon) : ((idx + 1) % 2 === 0 ? colPar : colNon)
                  ) : (
                    antePar && antPar && par ? ((idx + 1) % 2 === 0 ? colPar : colNon) : antePar && antPar && !par ? ((idx + 1) % 2 === 0 ? colPar : colNon) : antePar && !antPar && !par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : antePar && !antPar && par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : !antePar && antPar && par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : !antePar && antPar && !par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : !antePar && !antPar && !par ? ((idx + 1) % 2 === 0 ? colPar : colNon) : (idx + 1) % 2 === 0 ? colPar : colNon                   
                  ))
                                    
                  let tStyle = {border: '1px solid #ddd', padding: '8px', backgroundColor: colour}                  
                  return(                      
                    <tr key={idx}>
                      <td style={tStyle}>{eD + "/" + month[eM] + "/" + eY}</td>                    
                      <td style={tStyle}>{c.nombre}</td>
                      <td style={tStyle}>{c.cervecera}</td>
                      <td style={tStyle}>{c.estilo}</td>
                      <td style={tStyle}>{factura.proveedor}</td>
                      <td style={tStyle}>{c.tipo}</td> 
                      <td style={tStyle}>{Num.format(c.cantidad)}</td>                         
                      <td style={tStyle}>{Num.format(c.vendidas)}</td>
                      <td style={tStyle}>{MXN.format(c.precio)}</td>
                      <td style={tStyle}>{c.activo ? 'En carta' : 'Desactivada'}</td>                         
                      <td style={tStyle}>{Num.format(c.cantidad - c.vendidas)}</td>                                                   
                    </tr>
                  )
                }): null}
                {factura.merch ? factura.merch.map((c, idx) => {
                  let s = factura.merch.length;
                  let b = factura.cerveza.length > 0 ? factura.cerveza.length : 2;
                  let par = s % 2 === 0 ? true : false;
                  let bpar = b % 2 === 0 ? true : false;
                  let colour = (idf === 0) ? (
                    (idx + 1) % 2 === 0 ? colPar : colNon
                  ) : (idf === 1 ? (
                    !bpar && par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : !bpar && !par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : bpar && !par ? ((idx + 1) % 2 === 0 ? colPar : colNon) : ((idx + 1) % 2 === 0 ? colPar : colNon)
                  ) : (
                    antPar && bpar && par ? ((idx + 1) % 2 === 0 ? colPar : colNon) : antPar && bpar && !par ? ((idx + 1) % 2 === 0 ? colPar : colNon) : antPar && !bpar && !par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : antPar && !bpar && par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : !antPar && bpar && par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : !antPar && bpar && !par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : !antPar && !bpar && !par ? ((idx + 1) % 2 === 0 ? colNon : colPar) : (idx + 1) % 2 === 0 ? colPar : colNon                   
                  ))
                  let tStyle = {border: '1px solid #ddd', padding: '8px', backgroundColor: colour}                  
                  return(                      
                    <tr key={idx}>
                      <td style={tStyle}>{eD + "/" + month[eM] + "/" + eY}</td>
                      <td style={tStyle}>{c.producto}</td>
                      <td style={tStyle}>n/a</td>
                      <td style={tStyle}>n/a</td>
                      <td style={tStyle}>{factura.proveedor}</td>
                      <td style={tStyle}>merch {c.capacidad}</td>
                      <td style={tStyle}>{Num.format(c.cantidad)}</td>
                      <td style={tStyle}>n/a</td>
                      <td style={tStyle}>{MXN.format(c.costo)}</td> 
                      <td style={tStyle}>{c.activo ? 'En carta' : 'Desactivada'}</td>                         
                      <td style={tStyle}>{Num.format(c.cantidad)}</td>                           
                    </tr>
                  )
                }): null}    
                </tbody>                                                              
              )
            }) : null}            
          </table>
        </div>
      </div>

    </div>
  )
}

export default Facturas;